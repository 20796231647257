"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.School = void 0;
var Base_1 = require("./Base");
var School = /** @class */ (function (_super) {
    __extends(School, _super);
    function School(params) {
        var _this = _super.call(this, params) || this;
        var _a = params !== null && params !== void 0 ? params : {}, name = _a.name, colors = _a.colors;
        _this.name = name;
        _this.colors = colors;
        return _this;
    }
    Object.defineProperty(School.prototype, "registeredAmbassadors", {
        get: function () {
            return 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(School.prototype, "forLabel", {
        get: function () {
            return { label: this.name, value: this._id };
        },
        enumerable: false,
        configurable: true
    });
    return School;
}(Base_1.Base));
exports.School = School;
