import Formulaire from "../../components/Formulaire/Formulaire";
import React from 'react';
import {useAppSelector} from "../../store/hooks";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function MyInformation({}) {
    const userInfos = useAppSelector(state => state.userInfos);

    return (
        <div className={"flex justify-center items-center h-full"}>
            <div className="max-w-[500px] bg-white p-12 rounded-[40px] flex justify-center flex-col">
                <FontAwesomeIcon icon={faUser} size="xl"
                                 className="p-4 border-black rounded-full aspect-square w-[60px] h-[60px] m-auto"/>
                <h2 className={"text-center font-bold text-xl mb-6"}>Mes informations</h2>
                <Formulaire objectToModify={{objectData: userInfos, objectType: "users"}}></Formulaire>
            </div>
        </div>
    );
}

export default MyInformation;
