import React from "react";
import clsx from "clsx";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox } from "@fortawesome/free-solid-svg-icons";

function SmallCard({
  texte = null,
  keyFigure = null,
  isEventDay = false,
  icon = faInbox,
}) {
  return (
    <>
      <div
        className={clsx(
          "w-full h-fit-content flex flex-row justify-between rounded-3xl px-8 py-4",
          isEventDay ? "bg-[#FFFBEF]" : " bg-gray-100"
        )}
      >
        <div>
          <h3 className="text-sm mb-1">{texte}</h3>
          <p className="text-4xl font-bold">{keyFigure}</p>
        </div>
        <div className="flex justify-center items-center">
          <FontAwesomeIcon icon={icon} size="3x" />
        </div>
      </div>
    </>
  );
}

export default SmallCard;
