import React from 'react';
import "./building.css";

export default function Building({}) {
    return (
        <>
            <h1 className={"text-3xl max-w-full"}>Cette page n'est pas disponible pour le moment...</h1>
            <div className="loading loading02">
                <span>B</span>
                <span>U</span>
                <span>I</span>
                <span>L</span>
                <span>D</span>
                <span>I</span>
                <span>N</span>
                <span>G</span>
            </div>
        </>
    );
}
