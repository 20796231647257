// IMPORT DU STORE REDUX
import { store } from "../../store";

// API CALL
import { fetchAPIwithToken } from "../API";

// CONSTANTES
const BASE_URL = process.env.REACT_APP_API_URL;
const API_URL = `${BASE_URL}api/`;

// Récupération de la liste des utilisateurs pour l'application
export async function getTypeFields() {
  try {
    const result = await fetchAPIwithToken(
      API_URL + "typeFields",
      `JWT ${store.getState().userInfos.tokenAPI}`
    );
    return result?.result;
  } catch (error) {
    console.error(error);
    return {
      error: true,
      message: "Une erreur est survenue lors de l'accès à l'API.",
    };
  }
}
