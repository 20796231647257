import React, {useEffect, useState} from "react";
import chroma from "chroma-js";
import clsx from "clsx";
import {getAPI, putAPI} from "src/services/API/requestsManager";
import {ChromePicker} from "react-color";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faX} from "@fortawesome/free-solid-svg-icons";

import {useTheme} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Building from "../components/Building/Building";

function Customization() {
    /* Static Data */
    const labels = {
        inputEmail: "Email",
        infoEmail: "lola.louvounier@next-u.fr",
        inputPhone: "Téléphone portable",
        infoPhone: "06439876",
        nextEvent: "5",
        eventDone: "8",
        infoNextEvent: "Evènements à venir",
        infoEventDone: "Evènements fait cette année",
        openButton: "Ouvrir",
        closeButton: "Fermer",
        progressRank: "Rang",
        progressLevel: "Niveau",
        progressPoint: "Point",
    };

    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(true);
    const [isBlinking, setIsBlinking] = useState(true);
    const [saveStatus, setSaveStatus] = useState(0);
    const [elementHover, setElementHover] = useState("");
    const [nextUid, setNextUid] = useState("");
    const [primaryColorSelected, setPrimaryColorSelected] = useState("#0086cc");
    const [secondaryColorSelected, setSecondaryColorSelected] =
        useState("#ffc229");
    const [selectedColorForModification, setSelectedColorForModification] =
        useState(0);

    useEffect(() => {
        getAPI({objectType: "schools", options: "/name/NEXT-U"}).then(
            (schools) => {
                setNextUid(schools._id);
                setPrimaryColorSelected(schools?.colors?.primary);
                setSecondaryColorSelected(schools?.colors?.secondary);
                setIsLoading(false);
                setSaveStatus(1);
            }
        );
    }, []);

    useEffect(() => {
        setTimeout(
            () => {
                setIsBlinking(!isBlinking);
            },
            isBlinking ? 1500 : 500
        );
    }, [isBlinking]);

    // -- Color function
    const primaryColor = primaryColorSelected;
    const secondaryColor = secondaryColorSelected;
    const primaryColorDark = chroma(primaryColor).darken().hex();
    const primaryColorExtradark = chroma(primaryColor).darken(2).hex();
    const primaryColorLight = chroma(primaryColor).alpha(0.3).hex();
    const primaryColorExtraLight = chroma(primaryColor).alpha(0.1).hex();
    // -----------------------------------------------------------------
    const secondaryColorDark = chroma(secondaryColor).darken().hex();
    const secondaryColorExtradark = chroma(secondaryColor).darken(2).hex();
    const secondaryColorLight = chroma(secondaryColor).alpha(0.3).hex();
    const secondaryColorExtraLight = chroma(secondaryColor).alpha(0.1).hex();

    const saveColors = () => {
        setIsLoading(true);
        putAPI({
            objectType: "schools",
            idToModify: nextUid,
            dataToTransfert: {
                colors: {
                    primary: primaryColorSelected,
                    secondary: secondaryColorSelected,
                },
            },
        }).then((res) => {
            if (!res) {
                alert("Une erreur est survenue lors de l'enregistrement des données");
                setSaveStatus(2);
            } else
                setTimeout(() => {
                    setSaveStatus(1);
                    setIsLoading(false);
                }, 500);
        });
    };

    return (<Building/>);

    return (
        <>
            <div
                className={clsx(
                    "absolute bg-black bg-opacity-50 backdrop-blur-sm w-full h-full z-10 left-0 top-0 flex justify-center items-center",
                    selectedColorForModification !== 1 ? "hidden" : ""
                )}
            >
                <div className="h-fit relative">
                    <FontAwesomeIcon
                        icon={faX}
                        onClick={() => setSelectedColorForModification(0)}
                        className="cursor-pointer absolute -right-5 -top-5 z-30 h-4 w-4 bg-gray-400 rounded-full p-0.5"
                    />
                    <ChromePicker
                        color={primaryColorSelected}
                        onChange={(color) => {
                            setSaveStatus(0);
                            setPrimaryColorSelected(color.hex);
                        }}
                        colors={[]}
                    />
                </div>
            </div>
            <div
                className={clsx(
                    "absolute bg-black bg-opacity-50 backdrop-blur-sm w-full h-full z-10 left-0 top-0 flex justify-center items-center",
                    selectedColorForModification !== 2 ? "hidden" : ""
                )}
            >
                <div className="h-fit relative">
                    <FontAwesomeIcon
                        icon={faX}
                        onClick={() => setSelectedColorForModification(0)}
                        className="cursor-pointer absolute -right-5 -top-5 z-30 h-4 w-4 bg-gray-400 rounded-full p-0.5"
                    />
                    <ChromePicker
                        color={secondaryColorSelected}
                        onChange={(color) => {
                            setSaveStatus(0);
                            setSecondaryColorSelected(color.hex);
                        }}
                        colors={[]}
                    />
                </div>
            </div>
            <h4 className="text-2xl text-center mb-5">
                Palette de couleur de l'application mobile
            </h4>
            <div className="bg-white rounded-md px-20 py-10 relative">
                <div className="absolute right-8 top-8">
                    <LoadingButton
                        disabled={saveStatus === 1}
                        loading={isLoading}
                        loadingPosition="start"
                        startIcon={
                            saveStatus === 1 ? (
                                <CheckCircleIcon/>
                            ) : saveStatus === 2 ? (
                                <ReportGmailerrorredIcon/>
                            ) : (
                                <SaveIcon/>
                            )
                        }
                        onClick={saveColors}
                        style={{
                            color: theme.palette.getContrastText("#fff"),
                        }}
                    >
                        {saveStatus === 1
                            ? "Enregistré"
                            : saveStatus === 2
                                ? "Erreur"
                                : "Enregistrer"}
                    </LoadingButton>
                </div>
                <div className="flex justify-center gap-20">
                    <div className="flex flex-col items-center gap-5">
                        <h4 className="text-center text-1xl">Couleur primaire</h4>
                        <div
                            onClick={() => setSelectedColorForModification(1)}
                            className={
                                "w-24 h-24 rounded-full flex justify-center border-2 border-neutral-500 cursor-pointer hover:drop-shadow-xl"
                            }
                            style={{backgroundColor: primaryColor}}
                        ></div>
                    </div>
                    <div className="flex flex-col items-center gap-5">
                        <h4 className="text-center text-1xl">Couleur secondaire</h4>
                        <div
                            onClick={() => setSelectedColorForModification(2)}
                            className={
                                " w-24 h-24 rounded-full flex justify-center border-2 border-neutral-500 cursor-pointer hover:drop-shadow-xl"
                            }
                            style={{backgroundColor: secondaryColor}}
                        ></div>
                    </div>
                </div>
                <div className="flex flex-row ">
                    <div className="flex flex-col w-2/3">
                        <div className="flex flex-row items-center gap-40 w-full">
                            <div className="flex flex-col gap-1">
                                <h4 className="text-1xl underline mb-3">Palette générées :</h4>
                                <div
                                    className={"w-80 h-12"}
                                    style={{backgroundColor: primaryColorExtradark}}
                                ></div>
                                <div
                                    className={"w-80 h-12"}
                                    style={{backgroundColor: primaryColorDark}}
                                ></div>
                                <div
                                    className={"w-80 h-12"}
                                    style={{backgroundColor: primaryColor}}
                                ></div>
                                <div
                                    className={"w-80 h-12"}
                                    style={{backgroundColor: primaryColorLight}}
                                ></div>
                                <div
                                    className={"w-80 h-12"}
                                    style={{backgroundColor: primaryColorExtraLight}}
                                ></div>
                            </div>
                            <div className="flex flex-row">
                                <div className="flex flex-col 2xl:flex-row gap-10 2xl:gap-24">
                                    <div className="">
                                        <div
                                            className="rounded-lg w-32 h-32 drop-shadow-xl"
                                            style={{backgroundColor: primaryColorSelected}}
                                        >
                                            <h4 className="text-4xl pt-5 text-white font-bold text-center">
                                                {labels.eventDone}
                                            </h4>
                                            <h4 className="text-1xl text-white text-center">
                                                {labels.infoEventDone}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-between">
                                        <div className="drop-shadow-xl">
                                            <button
                                                className="text-white font-bold py-2 px-4 rounded drop-shadow-xl"
                                                onMouseEnter={() => setElementHover("closeButton")}
                                                onMouseLeave={() => setElementHover("")}
                                                style={{
                                                    backgroundColor:
                                                        elementHover === "closeButton"
                                                            ? primaryColorLight
                                                            : primaryColorSelected,
                                                }}
                                            >
                                                {labels.closeButton}
                                            </button>
                                        </div>
                                        <div className="drop-shadow-xl">
                                            <button
                                                className="bg-transparent  font-semibold py-2 px-4 border rounded drop-shadow-xl"
                                                onMouseEnter={() => setElementHover("openButton")}
                                                onMouseLeave={() => setElementHover("")}
                                                style={{
                                                    backgroundColor:
                                                        elementHover === "openButton"
                                                            ? primaryColor
                                                            : "transparent",
                                                    borderColor:
                                                        elementHover === "openButton"
                                                            ? "transparent"
                                                            : primaryColor,
                                                    color:
                                                        elementHover === "openButton"
                                                            ? "white"
                                                            : primaryColor,
                                                }}
                                            >
                                                {labels.openButton}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-between ">
                                        <div
                                            className="h-12 w-20 rounded-3xl flex flex-row items-center justify-center m1"
                                            style={{
                                                background: primaryColorDark,
                                            }}
                                        >
                                            <h4
                                                className="text-1xl text-white text-center ml-1"
                                                style={{
                                                    color:
                                                        theme.palette.getContrastText(primaryColorDark),
                                                }}
                                            >
                                                ON&nbsp;
                                            </h4>
                                            <div className="bg-white h-10 w-10 rounded-full"></div>
                                        </div>
                                        <div
                                            className="h-12 w-20 rounded-3xl flex flex-row-reverse items-center justify-center m1"
                                            style={{
                                                background: primaryColorLight,
                                            }}
                                        >
                                            <h4
                                                className="text-1xl text-center ml-1"
                                                style={{
                                                    color: "black",
                                                }}
                                            >
                                                OFF
                                            </h4>
                                            <div className="bg-white h-10 w-10 rounded-full"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-600 w-40 h-0.5 relative block m-2 rounded-full"></div>
                        <div className="flex items-center gap-40 ">
                            <div className="flex flex-col gap-1">
                                <div
                                    className={"w-80 h-12"}
                                    style={{backgroundColor: secondaryColorExtradark}}
                                ></div>
                                <div
                                    className={"w-80 h-12"}
                                    style={{backgroundColor: secondaryColorDark}}
                                ></div>
                                <div
                                    className={"w-80 h-12"}
                                    style={{backgroundColor: secondaryColor}}
                                ></div>
                                <div
                                    className={"w-80 h-12"}
                                    style={{backgroundColor: secondaryColorLight}}
                                ></div>
                                <div
                                    className={"w-80 h-12"}
                                    style={{backgroundColor: secondaryColorExtraLight}}
                                ></div>
                            </div>
                            <div className="flex flex-col">
                                <div
                                    className="flex items-center justify-evenly flex-col 2xl:flex-row gap-10 2xl:gap-24">
                                    <div
                                        className="rounded-lg w-32 h-32 drop-shadow-xl"
                                        style={{
                                            backgroundColor: secondaryColor,
                                        }}
                                    >
                                        <h4 className="text-4xl pt-5 text-white font-bold text-center">
                                            {labels.nextEvent}
                                        </h4>
                                        <h4 className="text-1xl text-white text-center">
                                            {labels.infoNextEvent}
                                        </h4>
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="">
                                            <h4 className="text-1xl pt-5 font-bold text-start">
                                                {labels.inputEmail}
                                            </h4>
                                            <div
                                                className="w-7 h-0.5 relative block rounded-full mb-2"
                                                style={{
                                                    backgroundColor: secondaryColor,
                                                }}
                                            ></div>
                                            <div
                                                className="border-green-500 border p-2 rounded bg-white flex flex-row justify-between items-center w-64"
                                                style={{
                                                    boxShadow:
                                                        secondaryColorExtraLight +
                                                        " 0px 14px 28px, " +
                                                        secondaryColorLight +
                                                        " 0px 10px 10px",
                                                }}
                                            >
                                                <h4 className="pl-1 pr-1 text-1xl text-start">
                                                    {labels.infoEmail}
                                                </h4>
                                                <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className="h-3 w-3 bg-green-400 rounded-full p-1"
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <h4 className="text-1xl pt-5 font-bold text-start">
                                                {labels.inputPhone}
                                            </h4>
                                            <div
                                                className="w-7 h-0.5 relative block rounded-full mb-2"
                                                style={{
                                                    backgroundColor: secondaryColor,
                                                }}
                                            ></div>
                                            <div
                                                className="border-red-500 border p-2 rounded bg-white flex flex-row justify-between items-center w-64"
                                                style={{
                                                    boxShadow:
                                                        secondaryColorExtraLight +
                                                        " 0px 14px 28px, " +
                                                        secondaryColorLight +
                                                        " 0px 10px 10px",
                                                }}
                                            >
                                                <h4
                                                    className={clsx(
                                                        "pl-1 text-1xl text-start pr-1 ",
                                                        isBlinking ? "border-r-2 border-gray-400" : ""
                                                    )}
                                                >
                                                    {labels.infoPhone}
                                                </h4>
                                                <FontAwesomeIcon
                                                    icon={faX}
                                                    className="h-3 w-3 bg-red-400 rounded-full p-1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-fit">
                                        <h4 className="text-1xl text-start text-gray-700">
                                            {labels.progressRank}
                                        </h4>
                                        <h4 className="text-1xl text-start text-gray-700">
                                            {labels.progressLevel}
                                        </h4>
                                        <h4 className="text-1xl text-start text-gray-700">
                                            {labels.progressPoint}
                                        </h4>
                                        <div
                                            className="w-7 h-0.5 relative block rounded-full mb-2"
                                            style={{
                                                backgroundColor: secondaryColor,
                                            }}
                                        ></div>
                                        <div
                                            className="rounded-full h-fit w-64 border-2 items-center relative"
                                            style={{
                                                boxShadow:
                                                    secondaryColorExtraLight +
                                                    " 0px 14px 28px, " +
                                                    secondaryColorLight +
                                                    " 0px 10px 10px",
                                            }}
                                        >
                                            <div
                                                className="rounded-full h-6 w-48 items-center"
                                                style={{
                                                    background:
                                                        "linear-gradient(90deg, " +
                                                        secondaryColorDark +
                                                        ", " +
                                                        secondaryColorLight +
                                                        ")",
                                                }}
                                            ></div>
                                            <h4 className="absolute right-2 top-1/2 -translate-y-1/2 font-bold">
                                                80%
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Customization;
