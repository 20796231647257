import React from 'react';
import {Link} from "react-router-dom";

function Notification({datetime = new Date(), type = "", subtype = ""}: {
    datetime: Date,
    type: string,
    subtype: string
}) {

    let notificationMessage = "";
    let notificationTitle = "";

    switch (subtype) {
        case "beginning_event":
            notificationTitle = "Début de l'évènement !"
            notificationMessage =
                "L'événement {0} débutera le {1} à {2}";
            break;
        case "end_event":
            notificationTitle = "Fin de l'évènement !"
            notificationMessage =
                "L'événement {1} prendra fin le {2} à {3}";
            break;
        case "new_level":
            notificationTitle = "Nouveau niveau !"
            notificationMessage = "Tu as changé de niveau, tu grandis vite petit ambassador.";
            break;
        case "podium":
            notificationTitle = "Nouveau classement !"
            notificationMessage = "Tu es arrivé dans le top 3 du classement, bravo !";
            break;
        case "form_ambassador":
            notificationTitle = "Niveau ambassador !"
            notificationMessage = "Tu as changé du niveau ambassador ! Continue comme ça et tu sera le meilleur ambassador !";
            break;
        case "admin_register":
            notificationTitle = "Inscrit à un nouvel évènement !"
            notificationMessage = "Un nouvel évènement pour le {0} est disponible dans ton calendrier ambassador, vient vite t'inscrire.";
            break;
        case "event_validation":
            notificationTitle = "Demande acceptée !"
            notificationMessage = "Ta demande pour l'évènement {0} a été accepté";
            break;
        case "event_rejection":
            notificationTitle = "Demande refusée !"
            notificationMessage = "Ta demande pour l'évènement {0} a été refusée";
            break;
        default:
            if (type === "new_event") {
                notificationTitle = "Nouvel évènement !"
                notificationMessage = "Un nouvel évènement est disponible vient vite t'inscrire 🥳"
            } else {
                notificationTitle = "Nouvel notification !"
                notificationMessage = "Tu as une notification à voir dant on application Amby-u";
            }
            break;
    }

    return (
        <div className="divide-y divide-gray-100 ">
            <Link to="#" className="flex px-4 py-3 hover:bg-gray-100">
                <div className="flex-shrink-0">
                    <div
                        className="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-blue-600 border border-white rounded-full dark:border-gray-800">
                        <svg
                            className="w-3 h-3 text-white"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
                            <path
                                d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
                        </svg>
                    </div>
                </div>
                <div className="w-full pl-3">
                    <div className="text-gray-700 text-sm mb-1">
                        {notificationTitle}
                    </div>
                    <div className="text-gray-500 text-sm mb-2">
                        {notificationMessage}
                    </div>
                    <div className="text-xs text-blue-600">
                        {/*a few moments ago*/}
                        {datetime.toLocaleDateString()}
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default Notification;
