// API CALL
import { fetchAPIwithToken } from "../API";

// STORE
import { store } from "../../store";

// CONSTANTES
const BASE_URL = process.env.REACT_APP_API_URL;
const API_URL = `${BASE_URL}api/`;

// Récupération de la liste des ambassadorulaires
export async function getAmbassadors() {
    try {
        const result = await fetchAPIwithToken(
            API_URL + "users/role/Ambassador",
            `JWT ${store.getState().userInfos.tokenAPI}`
        );
        return result?.result;
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message: "Une erreur est survenue lors de l'accès à l'API.",
        };
    }
}
