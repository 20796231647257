import React, { useState, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";

import TitlePage from "../components/TitlePage/TitlePage.tsx";
import MediumCard from "../components/MediumCard/MediumCard.jsx";

import { getFormsReceived } from "../services/Forms/index.js";

import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import TopGridDisplay from "../components/TopGridDisplay/TopGridDisplay";

function Inbox() {
  const [formReceived, setFormReceived] = useState([]);
  useEffect(() => {
    getFormsReceived().then((bdFormReceived) =>
      setFormReceived(bdFormReceived ?? [])
    );
  }, []);

  function generateAnswersCard(answers) {
    let nodeList = [];
    answers.forEach((answer) => {
      nodeList.push(
        <MediumCard
          icon={faClipboardList}
          key={answer._id}
          titre={answer.form.name}
          textes={[
            answer.form.school.name,
            answer.ambassador.lastname + " " + answer.ambassador.firstname,
            moment(answer.created_at ?? new Date()).format("D MMM Y, H:mm"),
          ]}
        />
      );
    });
    return nodeList;
  }

  return (
    <>
      <TopGridDisplay>{generateAnswersCard(formReceived)}</TopGridDisplay>
    </>
  );
}

export default Inbox;
