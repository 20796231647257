import React from 'react';
import {Link, useLocation} from "react-router-dom";
import clsx from "clsx";
import {getRoutePathByName} from "../../../routes/routes";

function Parameters({
                        close = () => {
                        }
                    }: { close: Function }) {
    const location = useLocation();
    const isSelected = (routeName: string) => {
        return location.pathname === getRoutePathByName(routeName);
    };

    const renderLink = (routeName: string, label: string) => {

        return (
            <Link
                className={clsx(
                    "flex items-center w-full h-12 px-4 rounded",
                    isSelected(routeName) ? "bg-gray-100" : "hover:bg-gray-200"
                )}
                to={getRoutePathByName(routeName)}
                onClick={() => close()}
            >
                <span
                    className={clsx(
                        "text-md font-bold",
                        isSelected(routeName) && "icon-clicked"
                    )}
                >
          {label}
        </span>
            </Link>
        );
    };

    return (
        <div className={"flex flex-col"}>
            {renderLink("my-infos", "Modifier mes informations")}
            {renderLink("security", "Sécurité")}
            {renderLink("notification", "Notification")}
            {renderLink("help", "Aide")}
            {renderLink("about", "À propos")}
        </div>
    );
}

export default Parameters;
