// IMPORT DU STORE REDUX
import { store } from "../../store";

// IMPORT DE LA FONCTION D'UPDATE DU TOKEN
import { updateToken } from "../../store/userInfos/userInfosSlice";

// IMPORT ROUTES
import { getRoutePathByName, getNameByRoutePath } from "../../routes/routes";

const refreshToken = (newToken) => {
    store.dispatch(updateToken(newToken));
}

// ============= FONCTION GLOBAL ============= //
export async function fetchAPIwithToken(url, token = null, method = "GET", data = {}) {
    if (["GET", "DELETE"].includes(method)) {
        const result = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            }
        })
            .then(
                (response) => {
                    if (response.status === 403) {
                        window.location.replace(getRoutePathByName('logout') + "?afterTo=" + getNameByRoutePath(window.location.pathname))
                    } else {
                        return response.json()
                    }
                })
            .then((data) => {
                if (data.newToken !== undefined) {
                    refreshToken(data.newToken)
                }
                return data;
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        return result ?? null;
    } else {
        const result = await fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: token
            },
            body: JSON.stringify(data)
        })
            .then(
                (response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        return result ?? null;
    }
}