import {createSlice} from '@reduxjs/toolkit'

export interface displayModalType {
    isRightDisplayed: boolean,
    isLeftDisplayed: boolean,
}

const initialDisplayModalState = {
    isRightDisplayed: false,
    isLeftDisplayed: false,
}

export const sideOversSlice = createSlice({
    name: 'displayModal',
    initialState: initialDisplayModalState,
    reducers: {
        displayRight: (state) => {
            state.isRightDisplayed = true;
        },
        hideRight: (state) => {
            state.isRightDisplayed = false;
        },
        displayLeft: (state) => {
            state.isLeftDisplayed = true;
        },
        hideLeft: (state) => {
            state.isLeftDisplayed = false;
        }
    }
})

// Action creators are generated for each case reducer function
export const {displayRight, hideRight, displayLeft, hideLeft} = sideOversSlice.actions

export default sideOversSlice.reducer
