import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

function ButtonAdd({ onclick, ...args }) {
	return (
		<div className="relative">
			<button
				type="button"
				className={clsx(
					"items-center text-sm font-semibold flex justify-center bg-white rounded-full h-12 w-12 text-5xl"
				)}
				id="menu-button"
				aria-expanded="true"
				aria-haspopup="true"
				onClick={onclick}
			>
				<img
					src={process.env.PUBLIC_URL + "/assets/images/add.svg"}
					alt=""
				/>
			</button>
		</div>
	);
}

export default ButtonAdd;
