import React, {useEffect, useState} from "react";

import Button from "../Buttons/Button";

// SELECT FIELD
import Select from "react-select";
import makeAnimated from "react-select/animated";

import * as functionSet from "./submitFunction";

import clsx from "clsx";

import {useDispatch} from "react-redux";
import {hide} from "../../store/displayModal/displayModal";

import {v4 as uuid} from "uuid";

// Import objectType
import {User} from "../../services/API/objectType.ts";
import {DynamicClass} from "../../services/API/ObjectTypes/DynamicClass";
import {ReactComponent as CloseIcon} from "../../assets/images/close-round-icon.svg";
import {DynamicRepo} from "../../services/API/Repositories/DynamicRepo";

const animatedComponents = makeAnimated();

const createFieldsFromObject = (object) => {
    const arrayOfField = [];
    const objectToSplit = object[0];
    const objectParams = object[1];
    for (const key in objectToSplit) {
        if (key.toLowerCase() !== "createdat")
            if (Object.hasOwnProperty.call(objectToSplit, key)) {
                const element = objectToSplit[key];
                if (
                    objectParams[key]?.display !== false ||
                    objectParams[key] === undefined
                )
                    if (
                        typeof element === "object" &&
                        !objectParams[key]?.type?.includes("select")
                    ) {
                        // Si c'est une array
                        if (element.length !== undefined) {
                            arrayOfField.push(
                                ...createFieldsFromObject([element[0], objectParams[key] ?? {}])
                            );
                        } else {
                            // Si c'est un object
                            arrayOfField.push(
                                ...createFieldsFromObject([element, objectParams[key] ?? {}])
                            );
                        }
                    } else {
                        const objectToReturn = {
                            label:
                                objectParams[key]?.label ??
                                key.charAt(0).toUpperCase() + key.slice(1),
                            type:
                                objectParams[key]?.type ??
                                (key.includes("id") ? "hidden" : "text"),
                            placeholder: objectParams[key]?.placeholder ?? key,
                            id: objectParams[key]?.id ?? key,
                            value:
                                objectParams[key]?.value ??
                                element?._id ??
                                (element?.[0]?._id ? element?.map((elm) => elm._id) : undefined) ??
                                element ??
                                "",
                            options: objectParams[key]?.options ?? [],
                            key: uuid(),
                            order: objectParams[key]?.order ?? 1000,
                            relatedRepo: objectParams[key]?.relatedRepo ?? null,
                            required: objectParams[key]?.required ?? false,
                            isValid: true
                        };
                        arrayOfField.push(objectToReturn);
                    }
            }
    }
    return arrayOfField.sort((a, b) => a.order - b.order);
};

const clearSelect = (value, options) => {
    const traitValue = [];
    if (typeof value !== "object") {
        traitValue.push(value.value ?? value);
    } else {
        if (
            (Object.values(value).length !== 0 && value.constructor === Object) ||
            value.length !== undefined
        )
            traitValue.push(...value);
    }
    return options.filter((opt) => traitValue.includes(opt.value));
};

const createObjectFromFields = (object, params, data) => {
    const objectReturned = {};
    let array = Object.getOwnPropertyNames(object);
    array.forEach((key) => {
        const element = object[key];
        if (params[key]?.display !== false) {
            if (
                typeof element === "object" &&
                element.length === undefined &&
                !params[key]?.type?.includes("select")
            ) {
                // Si c'est une object
                objectReturned[key] = createObjectFromFields(
                    element,
                    params[key],
                    data
                );
            } else {
                objectReturned[key] = data[key];
            }
        }
    });
    return objectReturned;
};

function Formulaire({objectToModify, saveFunction = undefined, ...args}) {
    const dispatch = useDispatch();

    const [computedFields, setComputedFields] = useState([]);
    const [stateForFields, setStateForFields] = useState([]);

    const [formData, setFormData] = useState();
    useEffect(() => {
        let fields = null;
        if (Array.isArray(objectToModify)) {
            fields = createFieldsFromObject(objectToModify);
        } else {
            const elemToModify = new DynamicClass(objectToModify.objectType, objectToModify.objectData);
            // console.log(objectToModify.objectData);
            // console.log(elemToModify.getFormFields());
            fields = createFieldsFromObject(elemToModify.getFormFields());
            // console.log(fields);
        }
        if (fields !== null) {
            setComputedFields(fields);
            setFormData(
                fields
                    ? Object.fromEntries(new Map(fields.map((fi) => [fi.id, fi.value])))
                    : {}
            );
        }
    }, [objectToModify]);

    const submit = () => {
        if (saveFunction)
            if (functionSet[saveFunction.function]) {
                // console.log(
                //     createObjectFromFields(objectToModify[0], objectToModify[1], formData)
                // );
                functionSet[saveFunction.function]({
                    ...saveFunction.args,
                    ...createObjectFromFields(
                        objectToModify[0],
                        objectToModify[1],
                        formData
                    ),
                }).then((res) => {
                    if (res)
                        dispatch(hide());
                });
            } else throw new Error("La fonction n'existe pas");
        else {
            const elemToModify = new DynamicClass(objectToModify.objectType, {
                _id: objectToModify.objectData?._id ?? null,
                ...formData
            });
            const checkRequired = checkAllRequiredField();
            if (checkRequired.isValid && elemToModify) {
                elemToModify.save((res) => {
                    if (res) {
                        dispatch(hide());
                    } else {
                        alert("Une erreur est survenue, veuillez réessayer dans quelques minutes.")
                    }
                });
            } else {
                const tmp = [...computedFields];
                tmp.forEach(field => {
                    field.isValid = !checkRequired.unvalidatedFields.includes(field.id);
                });
                setComputedFields(tmp);
            }
        }
    };

    const clearInput = (inpId) => {
        formData["size"] = 0;
        formData["original_file_name"] = "";
        setFormData({...formData, [inpId]: ""});
    }

    useEffect(() => {
        for (const formDataKey in formData) {
            const formDataValue = formData[formDataKey];
            // console.log(formDataKey, formDataValue);
        }
    }, [formData]);

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.toString().substr(reader.result.toString().indexOf(',') + 1));
            reader.onerror = error => reject(error);
        });
    }

    const checkAllRequiredField = () => {
        const unvalidatedFields = computedFields.filter(field => {
            if (field.required === true) {
                if (!(formData[field.id] !== "" && formData[field.id] !== null && formData[field.id] !== undefined)) {
                    return true;
                }
            }
            return false;
        }).map(field => field.id);
        return {isValid: unvalidatedFields.length === 0, unvalidatedFields: unvalidatedFields};
    }

    const renderFields = (fieldsToRender) => {
        const fieldRendered = [];
        fieldsToRender.forEach((field) => {
            if (field.type === "hidden") {
                fieldRendered.push(
                    <input
                        key={field.key}
                        type={field.type}
                        id={field.id}
                        value={field.value ?? ""}
                        disabled={true}
                    />
                );
            } else if (field.type?.includes("select-")) {
                if (field?.relatedRepo && stateForFields[field.id] === undefined) {
                    const repository = new DynamicRepo(field.relatedRepo);
                    repository?.getAll().then(result => {
                        const tmp = [];
                        // console.log(result?.[0]?.forLabel)
                        if (result?.[0]?.forLabel) {
                            tmp.push(...result.map(obj => obj.forLabel));
                        }
                        if (Array.isArray(field.options))
                            tmp.push(...field.options);
                        setStateForFields({...stateForFields, [field.id]: tmp});
                    })
                }
                fieldRendered.push(
                    <div className={clsx("mb-2")} key={field.key}>
                        <label
                            htmlFor={field.id}
                            className="block mb-1 text-sm font-medium text-gray-900"
                        >
                            {field.label} <span className='text-red-500'>{field.required ? "*" : ""}</span>
                        </label>
                        <Select
                            onChange={(choice) => {
                                const objPro = {};
                                objPro[field.id] = choice.value ?? choice.map((ch) => ch.value);
                                setFormData({...formData, ...objPro});
                            }}
                            closeMenuOnSelect={field.type !== "select-multiple"}
                            components={animatedComponents}
                            defaultValue={clearSelect(field.value, stateForFields[field.id] ?? field.options ?? [])}
                            isMulti={field.type === "select-multiple"}
                            name={field.id}
                            id={field.id}
                            options={stateForFields[field.id] ?? field.options ?? []}
                            className={"basic-multi-select"}
                            classNamePrefix="select"
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: field.isValid ? "rgb(209 213 219)" : "rgb(252 165 165)",
                                }),
                            }}
                            placeholder={field.placeholder}
                        />
                    </div>
                );
            } else if (field.type === "file") {
                fieldRendered.push(
                    <div className={clsx("mb-2")} key={field.key}>
                        <label
                            htmlFor={field.id}
                            className="block mb-1 text-sm font-medium text-gray-900"
                        >
                            {field.label} <span className='text-red-500'>{field.required ? "*" : ""}</span>
                        </label>
                        <input
                            type={field.type}
                            multiple={false}
                            id={field.id}
                            hidden={true}
                            placeholder={field.placeholder}
                            defaultValue={field.value ?? ""}
                            onChange={(newValue) => {
                                const newFile = newValue?.target?.files?.[0]
                                formData["size"] = newFile.size;
                                formData["original_file_name"] = newFile.name;
                                formData["type"] = newFile.type;
                                getBase64(newFile).then(resFile => {
                                    setFormData({...formData, [field.id]: resFile});
                                }).catch(err => {
                                    console.error(err);
                                })
                            }}
                            className="hidden"
                        />
                        <div
                            className={clsx("bg-gray-50 border flex flex-row gap-2 flex-wrap text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2", field.isValid ? "border-gray-300" : "border-red-300")}>
                            {formData["original_file_name"] && formData["original_file_name"] !== "" ?
                                <div
                                    className={"bg-gray-200 rounded w-fit px-2 py-0.5"}>{formData["original_file_name"] ?? ""}
                                    <CloseIcon className={"h-4 w-4 inline-block ml-1.5 align-sub cursor-pointer"}
                                               onClick={() => clearInput(field.id)}/>
                                </div> : <label
                                    htmlFor={field.id}
                                    className="block mb-1 text-sm font-medium text-gray-900"
                                >
                                    Sélectionner un fichier
                                </label>
                            }
                        </div>
                    </div>
                );
            } else if (field.type === "checkbox") {
                fieldRendered.push(
                    <div className={clsx("my-2")} key={field.key}>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input type="checkbox"
                                   className="sr-only peer"
                                   id={field.id}
                                   defaultChecked={field.value ?? false}
                                   onChange={(newValue) => {
                                       const objPro = {};
                                       objPro[field.id] = newValue.target.checked;
                                       setFormData({...formData, ...objPro});
                                   }}/>
                            <div
                                className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span
                                className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">{field.label}
                                <span className='text-red-500'>{field.required ? "*" : ""}</span></span>
                        </label>
                    </div>);
            } else {
                fieldRendered.push(
                    <div className={clsx("mb-2")} key={field.key}>
                        <label
                            htmlFor={field.id}
                            className="block mb-1 text-sm font-medium text-gray-900"
                        >
                            {field.label} <span className='text-red-500'>{field.required ? "*" : ""}</span>
                        </label>
                        <input
                            type={field.type}
                            id={field.id}
                            placeholder={field.placeholder}
                            defaultValue={field.value ?? ""}
                            onChange={(newValue) => {
                                const objPro = {};
                                objPro[field.id] = newValue.target.value;
                                setFormData({...formData, ...objPro});
                            }}
                            className={clsx("bg-gray-50 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5", field.isValid ? "border-gray-300" : "border-red-300")}
                        />
                    </div>
                );
            }
        });
        return fieldRendered;
    };
    return (
        <>
            <div className="flex flex-col p-5">
                {renderFields(computedFields ?? [])}
                <Button type={"primary"} onclick={submit} label={"Enregistrer"}/>
            </div>
        </>
    );
}

export default Formulaire;
