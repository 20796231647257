import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell, faUser} from "@fortawesome/free-regular-svg-icons";
import {faGear, faSignOut} from "@fortawesome/free-solid-svg-icons";
import {useAppDispatch, useAppSelector} from "src/store/hooks";

import {
    getBreadcrumbByRouteName,
    getNameByRoutePath,
    getRoutePathByName,
    getTitleByRouteName,
} from "../../routes/routes";
import TitlePage from "../TitlePage/TitlePage";
import openForm from "../Formulaire/openForm";
import Notification from "../Notification/Notification";
import {Notification as Notif} from "../../services/API/ObjectTypes/Notification";
import {Notifications} from "../../services/API/Repositories/Notifications";
import {displayRight} from "../../store/sideOvers/sideOvers";

const NotificationRepo = new Notifications();

function Topbar() {
    // const dispatch = useAppDispatch();
    const userInfos = useAppSelector(state => state.userInfos);
    const currentPage = useLocation().pathname;
    const [showNotifications, setShowNotifications] = useState(false);
    const [notificationsList, setNotificationsList] = useState([]);

    useEffect(() => {
        NotificationRepo.getAll().then(notifications => {
            if (!notifications.error)
                setNotificationsList(notifications);
        });
    }, [])

    const toggleShowNotifications = () => {
        setShowNotifications((prevState) => !prevState);
    };

    const dispatch = useAppDispatch()
    const setIsParamsSideOverOpen = () => dispatch(displayRight());


    const showModal = () => {
        // const argsForComponent = {
        //     objectToModify: [
        //         userInfos,
        //         {
        //             firstname: {label: "Prénom"},
        //             lastname: {label: "Nom"},
        //             email: {label: "E-Mail"},
        //             tel: {label: "Téléphone"},
        //             password: {display: false},
        //             __v: {display: false},
        //             school: {display: false},
        //             roles: {display: false},
        //             stats: {display: false},
        //             updatedAt: {display: false},
        //             username: {display: false},
        //             tokenAPI: {display: false},
        //             isLoggedIn: {display: false},
        //             lastUpdate: {display: false},
        //         },
        //     ],
        //     saveFunction: "submitUserInfos",
        // };
        openForm({
            objectType: "users",
            objectToModify: userInfos,
            isDynamicClass: true,
            title: `${userInfos.lastname} ${userInfos.firstname} - (${userInfos.school.name})`
        })

        // dispatch(setComponent("Formulaire"));
        // dispatch(setArgsForComponent(argsForComponent));
        // dispatch(
        //     setTitle(
        //         `${userInfos.lastname} ${userInfos.firstname} - (${userInfos.school.name})`
        //     )
        // );
        // dispatch(display());
    };

    return (
        <nav
            id="topNavBar"
            className="pr-3 pt-3 dark:bg-gray-100 dark:bg-transparent bg-transparent -mr-10"
        >
            <div className="flex flex-wrap items-center justify-between mx-auto">
                <div className="flex flex-col">
          <span className="inline">
            <span className="self-center text-l font-semibold whitespace-nowrap text-gray-400">
              Pages
            </span>
              &nbsp;
              <span className="self-center text-l font-semibold whitespace-nowrap text-gray-700">
              <Link to={"/"}>/</Link>
            </span>
              &nbsp;
              <span className="self-center text-l font-semibold whitespace-nowrap text-gray-700">
              {/*<Link to={currentPage}>*/}
                  {getBreadcrumbByRouteName(getNameByRoutePath(currentPage))}
                  {/*</Link>*/}
            </span>
          </span>
                    <span className="pt-3">
            <TitlePage
                title={getTitleByRouteName(getNameByRoutePath(currentPage))}
            />
          </span>
                </div>
                <div className="hidden w-full md:block md:w-auto" id="navbar-solid-bg">
                    <ul className="flex flex-col mt-4 rounded-lg bg-gray-50 md:flex-row md:space-x-3 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent dark:border-gray-700">
                        <li className="flex justify-center">
                            <button
                                type="button"
                                data-dropdown-toggle="language-dropdown-menu"
                                className="relative inline-flex items-center p-3 text-sm font-medium text-center rounded hover:text-gray-100"
                                onClick={showModal}
                            >
                                <FontAwesomeIcon icon={faUser} size="xl" className="pr-3"/>
                                {`${userInfos.firstname} ${userInfos.lastname?.toUpperCase()}`}
                            </button>
                        </li>
                        <li className="flex items-center justify-center">
                            <div
                                onClick={setIsParamsSideOverOpen}
                                className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 hover:text-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-700 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-gray-100 md:dark:hover:bg-transparent"
                                aria-current="page"
                            >
                                <FontAwesomeIcon icon={faGear} size="xl"/>
                            </div>
                        </li>
                        <li>
                            <div>
                                <button
                                    type="button"
                                    onClick={toggleShowNotifications}
                                    className={`relative inline-flex items-center p-3 text-sm font-medium text-center rounded hover:text-gray-100 ${
                                        showNotifications ? "text-white" : "text-gray-700"
                                    }`}
                                >
                                    <FontAwesomeIcon icon={faBell} size="xl"/>
                                    <span className="sr-only">Notifications</span>
                                    {notificationsList.length > 0 &&
                                        <div
                                            className="absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-gray-700 bg-red-500 rounded-full -top-1 -right-1 dark:border-gray-900">
                                            {notificationsList.length ?? ""}
                                        </div>}
                                </button>

                                <div
                                    id="dropdownNotification"
                                    className={`absolute w-full max-w-sm bg-white divide-y divide-gray-100 rounded-lg shadow -translate-x-[80%] z-[1000] ${
                                        showNotifications ? "block" : "hidden"
                                    }`}
                                    aria-labelledby="dropdownNotificationButton"
                                >
                                    <div
                                        className="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-gray-50">
                                        Notifications
                                    </div>
                                    {notificationsList.length === 0 ? <div
                                        className="block px-4 py-2 font-medium text-center text-gray-700 rounded-t-lg bg-white rounded-b-lg">
                                        Aucune notification
                                    </div> : notificationsList.map((notif: Notif, index) => (
                                        <Notification
                                            key={notif._id?.toString() ?? "notif_" + index}
                                            datetime={notif.createdAt ?? new Date()}
                                            type={notif.type}
                                            subtype={notif.subtype}/>))}

                                    {notificationsList.length === 0 ? "" : <Link
                                        to="#"
                                        className="block py-2 text-sm font-medium text-center text-gray-900 rounded-b-lg bg-gray-50 hover:bg-gray-100"
                                    >
                                        <div className="inline-flex items-center ">
                                            <svg
                                                className="w-4 h-4 mr-2 text-gray-500"
                                                aria-hidden="true"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                                <path
                                                    fillRule="evenodd"
                                                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                            View all
                                        </div>
                                    </Link>}
                                </div>
                            </div>
                        </li>
                        <li className="flex items-center justify-center ">
                            <Link
                                to={getRoutePathByName("logout")}
                                className="block py-2 pl-3 pr-4 text-gray-700 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-gray-700 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent hover:text-gray-100"
                            >
                                <FontAwesomeIcon icon={faSignOut} size="xl"/>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Topbar;
