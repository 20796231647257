import React from "react";
import clsx from "clsx";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faChevronLeft, faChevronRight,} from "@fortawesome/free-solid-svg-icons";

function SideOver(props) {
    const [open, setOpen] = [props.open, props.setOpen];

    // useEffect(() => {
    //     setOpen(true);
    //     setTimeout(() => {
    //         setOpen(props.open);
    //     }, 10);
    // }, []);

    const [sideTransition, sidePosition] =
        (props.side ?? "") === "left"
            ? ["-translate-x-full", "left-0"]
            : ["translate-x-full", "right-0"];

    const title = props.title ?? "Paramètres";
    return (
        <div
            id="slideover-container"
            className={clsx("w-full h-full fixed inset-0 z-[9999]", open ? "" : "invisible")}
        >
            <div
                onClick={() => setOpen(false)}
                id="slideover-bg"
                className={clsx(
                    "w-full h-full duration-500 ease-out transition-all inset-0 absolute bg-gray-900",
                    open ? "opacity-50" : "opacity-0"
                )}
            ></div>
            <div
                id="slideover"
                className={clsx(
                    "w-96 bg-white absolute duration-300 ease-out transition-all flex flex-col h-screen",
                    sidePosition,
                    open ? "" : sideTransition
                )}
            >
                <div
                    onClick={() => setOpen(!open)}
                    className="absolute cursor-pointer text-gray-600 top-0 w-8 h-8 flex items-center justify-center right-0 mt-5 mr-5"
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                        ></path>
                    </svg>
                </div>
                <div
                    onClick={() => setOpen(false)}
                    className="bg-gray-200 rounded-r-lg h-16 pr-1 flex items-center absolute left-full top-1/2 transform-gpu -translate-y-1/2"
                >
                    <FontAwesomeIcon
                        icon={open ? faChevronLeft : faChevronRight}
                        size="xl"
                        className="pb-2 transform-gpu translate-y-0.5"
                    />
                </div>
                <header className="h-8 mt-5 px-9 text-2xl font-bold">{title}</header>
                <hr className="w-11/12 h-0.5 mx-auto mt-5 bg-gray-400 border-0 rounded"/>
                <main className="mb-auto py-5 px-5">{props.children}</main>
                <hr className="w-11/12 h-0.5 mx-auto my-5 bg-gray-400 border-0 rounded"/>
                <footer className="h-10 px-5">Footer</footer>
            </div>
        </div>
    );
}

export default SideOver;
