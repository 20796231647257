import React, {useCallback} from "react";
// import { Outlet } from "react-router-dom";
import Topbar from "../Topbar/Topbar";
import Sidebar from "../Sidebar/Sidebar";
import Modal from "../Modal/Modal";
import SideOver from "../SideOver/SideOver";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {displayRight, hideRight} from "../../store/sideOvers/sideOvers";
import Parameters from "../SideOver/content/Parameters";

function Navigation() {
    // const [isParamsSideOverOpen, setIsParamsSideOverOpen] = useState(true);
    const isRightSideOversDisplayed = useAppSelector((state) => state.sideOvers.isRightDisplayed);
    const dispatch = useAppDispatch()
    const setIsParamsSideOverOpen = useCallback(
        (isDisplayed) =>
            dispatch(isDisplayed ? displayRight() : hideRight())
        ,
        [dispatch]
    )

    return (
        <div>
            <Sidebar>
                <Modal/>
                <Topbar/>
                <SideOver
                    open={isRightSideOversDisplayed}
                    setOpen={setIsParamsSideOverOpen}
                    side="right"
                    title="Paramètres"
                >
                    <Parameters close={() => setIsParamsSideOverOpen(false)}/>
                </SideOver>
            </Sidebar>
        </div>
    );
}

export default Navigation;
