import React from "react";
import clsx from "clsx";

// BUTTONS
import ButtonMenu from "../Buttons/ButtonMenu.jsx";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox } from "@fortawesome/free-solid-svg-icons";

function MediumCard({
	titre = null,
	textes = null,
	icon = faInbox,
	menu = [],
}) {
	return (
		<>
			<div
				className={clsx(
					"w-full h-fit-content flex flex-row rounded-3xl px-6 py-4 bg-gray-100"
				)}
			>
				<div className="flex justify-center items-center">
					<FontAwesomeIcon icon={icon} size="3x" />
				</div>
				<div className="ml-7 flex flex-col" style={{ flex: 1 }}>
					<h3 className="text mb-1">{titre}</h3>
					<p className="text-sm">{textes[0]}</p>
					<p className="text-sm">{textes[1]}</p>
					<p className="text-sm">{textes[2]}</p>
				</div>
				<div className="flex items-center justify-center">
					<ButtonMenu rotateIcon={true} menuItems={menu} />
				</div>
			</div>
		</>
	);
}

export default MediumCard;
