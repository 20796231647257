import { createSlice } from '@reduxjs/toolkit'

export interface UserInfosSchool {
    _id: string;
    name: string;
    colors: {primary: string, secondary: string};
    updated_at: string;
    created_at: string;
}

export interface UserInfos {
    id: string,
    firstname: string,
    lastname: string,
    email: string,
    tel: string,
    tokenAPI: string,
    isLoggedIn: boolean,
    stats: Object,
    roles: Array<Object>,
    school: UserInfosSchool,
    lastUpdate: string,
}

const emptySchool = {
    _id: "",
    name: "",
    colors: {primary: "", secondary: ""},
    updated_at: "",
    created_at: "",
}

const originalUser:UserInfos = {
    id: "",
    firstname: "Lila",
    lastname: "Louvournier",
    email: "Email",
    tel: "Telephone",
    tokenAPI: "",
    isLoggedIn: true,
    stats: {
        nb_eventdone: 0,
        nb_formsended: 0
    },
    roles: [],
    school: emptySchool,
    lastUpdate: new Date().toISOString(),
}

export const userInfosSlice = createSlice({
    name: 'userInfos',
    initialState: originalUser,
    reducers: {
        setUserInfos: (state, action) => {
            return { ...state, ...action.payload }
        },
        updateToken: (state, action) => {
            state.tokenAPI = action.payload
        },
        logout: (state) => {
            state = {...state, ...originalUser}
        }
    }
})

// Action creators are generated for each case reducer function
export const { setUserInfos, logout, updateToken } = userInfosSlice.actions

export default userInfosSlice.reducer