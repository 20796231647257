// const mapKey = {
//     title: {nom: "name", default: ""},
//     id: {nom: "_id", default: ""},
//     startDate: {nom: "start_date", default: ""},
//     endDate: {nom: "end_date", default: ""},
//     ambassadors: {nom: "ambassadors", default: []},
//     notes: {nom: "notes", default: ""},
//     rRule: {nom: "rRule", default: ""},
//     address: {nom: "address", default: ""},
//     allDay: {nom: "allDay", default: false},
//     description: {nom: "description", default: ""},
//     represented_school: {nom: "represented_school", default: []},
//     rdv_address: {nom: "rdv_address", default: ""},
//     rdv_date: {nom: "rdv_date", default: ""},
//     updatedAt: {nom: "updatedAt", default: ""},
//     createdAt: {nom: "createdAt", default: ""}
// };

const mapKey = {
    title: {nom: "name", default: ""},
    event_id: {nom: "_id", default: ""},
    start: {nom: "start_date", default: ""},
    end: {nom: "end_date", default: ""},
    address: {nom: "address", default: ""},
    description: {nom: "description", default: ""},
    represented_school: {nom: "represented_school", default: []},
    rdv_address: {nom: "rdv_address", default: ""},
    rdv_date: {nom: "rdv_date", default: ""},
    updatedAt: {nom: "updatedAt", default: ""},
    createdAt: {nom: "createdAt", default: ""},
    allDay: {nom: "allDay", default: "false"},
};

export default mapKey
