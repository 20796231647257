import React, {useState} from "react";
import clsx from "clsx";

// CALENDRIER
import Planning from "../components/Scheduler/index.jsx";
import {CircularProgress} from "@mui/joy";

function Events() {
    const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    return (
        <>
            <div
                className={clsx(
                    "bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative",
                    isErrorDisplayed ? "" : "hidden"
                )}
                role="alert"
                id="error-scheduler"
            >
                <strong className="font-bold">Holy smokes!</strong>
                <span className="block sm:inline">
                  Something seriously bad happened.
                </span>
                <span
                    className="absolute top-0 bottom-0 right-0 px-4 py-3"
                    onClick={() => {
                        setIsErrorDisplayed(false);
                    }}
                >
                  <svg
                      className="fill-current h-6 w-6 text-red-500"
                      role="button"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                  >
                    <title>Close</title>
                    <path
                        d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
                  </svg>
                </span>
            </div>
            <div
                className="rounded-lg overflow-y-scroll bg-white relative"
                id="parent-scheduler-height"
            >
                {isLoading && (
                    <div className="h-full w-full absolute z-[1000]">
                        <div className={"absolute left-[calc(50%_-_40px)] top-[calc(50%_-_40px)]"}>
                            <div className={"fixed"}>
                                <CircularProgress variant={"plain"}
                                                  sx={{
                                                      '--CircularProgress-size': '80px',
                                                      '--CircularProgress-progressColor': "#202020",
                                                      "position": "fixed",
                                                      "color": "#202020"
                                                  }}> Loading </CircularProgress>
                            </div>
                        </div>
                    </div>)}
                <Planning onError={setIsErrorDisplayed} setIsLoading={setIsLoading}/>
            </div>
        </>
    );
}

export default Events;
