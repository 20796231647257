import React, { useState, useEffect } from "react";
import { alpha, styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Room from "@mui/icons-material/Room";
import {
  EditingState,
  IntegratedEditing,
  Resources,
  ViewState,
} from "devextreme-react/scheduler";
import {
  AllDayPanel,
  AppointmentForm,
  Appointments,
  AppointmentTooltip,
  ConfirmationDialog,
  DateNavigator,
  DayView,
  EditRecurrenceMenu,
  MonthView,
  Scheduler,
  TodayButton,
  Toolbar,
  ViewSwitcher,
  WeekView,
} from "devextreme-react/scheduler";

import moment from "moment";
import "moment/locale/fr";
import {
  deleteEvent,
  getEvents,
  setEvent,
  updateEvent,
} from "../../services/Events";

import { v4 as uuid } from "uuid";
import { getUsers } from "../../services/Users";

import schedulerToApi from "./transformers/event/schedulerToApi.js";
import apiToScheduler from "./transformers/event/apiToScheduler.js";

import userTransformer from "./transformers/userTransformer";
import LinearProgress from "@mui/material/LinearProgress";
import { Schools } from "../../services/API/Repositories/Schools";

const PREFIX = "scheduler";

const classes = {
  todayCell: `${PREFIX}-todayCell`,
  weekendCell: `${PREFIX}-weekendCell`,
  today: `${PREFIX}-today`,
  weekend: `${PREFIX}-weekend`,
};

const StyledWeekViewTimeTableCell = styled(WeekView.TimeTableCell)(
  ({ theme }) => ({
    [`&.${classes.todayCell}`]: {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.14),
      },
      "&:focus": {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
    },
    [`&.${classes.weekendCell}`]: {
      backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
      "&:hover": {
        backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
      },
      "&:focus": {
        backgroundColor: alpha(theme.palette.action.disabledBackground, 0.04),
      },
    },
  })
);

const StyledWeekViewDayScaleCell = styled(WeekView.DayScaleCell)(
  ({ theme }) => ({
    [`&.${classes.today}`]: {
      backgroundColor: alpha(theme.palette.primary.main, 0.16),
    },
    [`&.${classes.weekend}`]: {
      backgroundColor: alpha(theme.palette.action.disabledBackground, 0.06),
    },
  })
);

const TimeTableCell = (props) => {
  const { startDate } = props;
  const date = new Date(startDate);

  if (date.getDate() === new Date().getDate()) {
    return (
      <StyledWeekViewTimeTableCell {...props} className={classes.todayCell} />
    );
  }
  if (date.getDay() === 0 || date.getDay() === 6) {
    return (
      <StyledWeekViewTimeTableCell {...props} className={classes.weekendCell} />
    );
  }
  return <StyledWeekViewTimeTableCell {...props} />;
};

const DayScaleCell = (props) => {
  const { startDate, today } = props;

  if (today) {
    return (
      <StyledWeekViewDayScaleCell
        {...props}
        className={classes.today}
        formatDate={formatDayScaleDate}
      />
    );
  }
  if (startDate.getDay() === 0 || startDate.getDay() === 6) {
    return (
      <StyledWeekViewDayScaleCell
        {...props}
        className={classes.weekend}
        formatDate={formatDayScaleDate}
      />
    );
  }
  return (
    <StyledWeekViewDayScaleCell {...props} formatDate={formatDayScaleDate} />
  );
};

const TextEditor = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (props.type === "multilineTextEditor") {
    return null;
  }
  return <AppointmentForm.TextEditor {...props} />;
};

const formatDayScaleDate = (date, options) => {
  const momentDate = moment(date);
  const { weekday } = options;
  const frenchDate = momentDate.locale("fr-FR").format(weekday ? "dddd" : "D");
  return frenchDate[0].toUpperCase() + frenchDate.slice(1);
};

const BasicLayout = ({
  onFieldChange,
  appointmentData,
  resources,
  ...restProps
}) => {
  const SchoolRepo = new Schools();
  SchoolRepo.getAll().then((schools) => {
    onFieldChange({ allSchoolData: schools });
  });

  const getResourceByName = (nameResource) => {
    return resources.filter((res) => res.fieldName === nameResource)[0];
  };
  const onAddressChange = (newAddress) => {
    onFieldChange({ address: newAddress });
  };

  const onAppointmentAddressChange = (newAppointmentAddress) => {
    onFieldChange({ rdv_address: newAppointmentAddress });
  };

  const onAppointmentDateChange = (newAppointmentDate) => {
    onFieldChange({ rdv_date: newAppointmentDate });
  };

  // const onAmbassadorChange = (nextValue) => {
  //     onFieldChange({ambassadors: nextValue.ambassadors ?? []});
  // };

  const onRepresentedSchoolsChange = (nextValue) => {
    if (
      appointmentData.perSchoolRepresentatives !== null &&
      appointmentData.perSchoolRepresentatives !== undefined
    ) {
      Object.keys(appointmentData.perSchoolRepresentatives)
        .filter(function (i) {
          return nextValue.schools.indexOf(i) < 0;
        })
        .forEach((toDel) => {
          appointmentData.perSchoolRepresentatives[toDel] = 0;
          if (appointmentData.selectSchoolRepresentatives === toDel)
            appointmentData.selectSchoolRepresentatives = undefined;
        });
    }

    onFieldChange({ representedSchools: nextValue.schools ?? [] });
  };

  const onSelectedSchoolRepresentatives = (nextValue) => {
    onFieldChange({ selectSchoolRepresentatives: nextValue });
  };

  const generateObjectPerRepresentativesSchool = () => {
    const obj = appointmentData.perSchoolRepresentatives ?? {};
    appointmentData.representedSchools?.map((school) =>
      obj[school] ? null : (obj[school] = 0)
    );
    return obj;
  };

  const onPerSchoolRepresentativesChange = (nextValue, school) => {
    const currentSelected = {};
    nextValue = parseInt(nextValue);
    nextValue = isNaN(nextValue) ? 0 : nextValue;

    const allAmbassadors = getResourceByName("ambassadors");
    console.log(allAmbassadors.instances);
    console.log(
      appointmentData.allSchoolData.filter(
        (schoolLocal) => schoolLocal._id == school
      )?.[0]
    );
    // console.log(SchoolRepo.getSchoolById(school));

    if (nextValue < 0) nextValue = 0;
    currentSelected[school] = nextValue;

    onFieldChange({
      perSchoolRepresentatives: {
        ...generateObjectPerRepresentativesSchool(),
        ...currentSelected,
      },
    });
  };

  const getSchoolById = (idSchool) => {
    const school = getResourceByName("schools").instances.filter(
      (school) => school.id === idSchool
    );
    if (school) return school[0];
    return { text: "" };
  };

  const cleanArrayWithId = (arrayToClean) => {
    if (arrayToClean)
      return arrayToClean
        .map((toClean) => toClean?.id ?? toClean?._id ?? toClean)
        .filter((toClean) => toClean !== undefined);
    return [];
  };

  return (
    <AppointmentForm.BasicLayout
      appointmentData={appointmentData}
      onFieldChange={onFieldChange}
      {...restProps}
    >
      <AppointmentForm.Label
        text="Rendez vous"
        type="title"
        style={{ marginTop: 20 }}
      />
      <AppointmentForm.TextEditor
        value={appointmentData.rdv_address}
        onValueChange={onAppointmentAddressChange}
        placeholder="Adresse de l'évènement"
      />
      <AppointmentForm.DateEditor
        value={
          typeof appointmentData.rdv_date === "string"
            ? new Date(appointmentData.rdv_date)
            : appointmentData.rdv_date
        }
        onValueChange={onAppointmentDateChange}
        placeholder="Adresse de l'évènement"
        locale={"fr"}
      />

      {/*<AppointmentForm.Label text="Ambassador" type="title" style={{marginTop: 20}}/>*/}
      {/*<AppointmentForm.ResourceEditor style={{marginTop: 0}}*/}
      {/*                                resource={getResourceByName("ambassadors")}*/}
      {/*                                onResourceChange={onAmbassadorChange}*/}
      {/*                                value={cleanArrayWithId(appointmentData.ambassadors)}*/}
      {/*/>*/}

      <AppointmentForm.Label
        text="Écoles représentées"
        type="title"
        style={{ marginTop: 20 }}
      />
      <AppointmentForm.ResourceEditor
        style={{ marginTop: 0 }}
        resource={getResourceByName("schools")}
        onResourceChange={onRepresentedSchoolsChange}
        value={cleanArrayWithId(appointmentData.representedSchools)}
      />

      {cleanArrayWithId(appointmentData.representedSchools).length > 0
        ? cleanArrayWithId(appointmentData.representedSchools).map((sch) => (
            <div className="grid grid-cols-2 gap-4" key={sch}>
              <AppointmentForm.Label
                text={"Réprésentants " + getSchoolById(sch).text}
                type="title"
                style={{ marginTop: 20 }}
              />
              <AppointmentForm.TextEditor
                type={"numberEditor"}
                readOnly={!appointmentData.allSchoolData}
                min={0}
                value={
                  appointmentData.perSchoolRepresentatives
                    ? appointmentData.perSchoolRepresentatives[sch]
                    : 0
                }
                onValueChange={(nextValue) =>
                  onPerSchoolRepresentativesChange(nextValue, sch)
                }
              />
            </div>
          ))
        : null}
    </AppointmentForm.BasicLayout>
  );
};

function Planning({ locale = "fr-FR", onError, ...args }) {
  const [loading, setLoading] = useState(true);
  const [allAppointments, setAllAppointments] = useState([]);
  const [addedAppointment, setAddedAppointment] = useState({});
  const [appointmentChanges, setAppointmentChanges] = useState({});
  const [isAppointmentFormDisplayed, setIsAppointmentFormDisplayed] =
    useState(undefined);
  const [appointmentFormDisplayed, setAppointmentFormDisplayed] =
    useState(undefined);
  const [editingAppointment, setEditingAppointment] = useState(undefined);
  const [ressourcesScheduler, setRessourcesScheduler] = useState([]);
  const [isUpdatingErrorEvent, setIsUpdatingErrorEvent] = useState(false);

  // RECUPERATION DE TOUS LES RDV
  useEffect(() => {
    setLoading(false);
    getUsers().then((users) => {
      setRessourcesScheduler([
        {
          fieldName: "ambassadors",
          title: "Ambassadors",
          instances: userTransformer(users),
          allowMultiple: true,
        },
        {
          fieldName: "schools",
          title: "Ecoles",
          instances: allAvailableSchool(users),
          allowMultiple: true,
        },
      ]);
    });
    getEvents().then((events) => {
      console.log(events);
      setAllAppointments(apiToScheduler(events));
      setLoading(false);
    });
  }, []);

  const allAvailableSchool = (userToCheck) => {
    const allUniqueSchool = new Set(
      userToCheck.map((user) => JSON.stringify(user.school))
    );
    return [...allUniqueSchool].map((school) => {
      const schoolObj = JSON.parse(school);
      return {
        id: schoolObj._id,
        text: schoolObj.name,
        color: schoolObj.colors?.primary ?? "#C2C2C2",
      };
    });
  };

  const localizationMessages = {
    "fr-FR": {
      allDay: "Temps plein",
      today: "Aujourd'hui",
      discardButton: "Rejeter",
      confirmCancelMessage: "Rejeter les modifications non sauvegardées ?",
      confirmDeleteMessage:
        "Êtes-vous sûr de vouloir supprimer cet évènement ?",
      cancelButton: "Annuler",
      deleteButton: "Supprimer",
      daysLabel: "Jours",
      detailsLabel: "Détails",
      titleLabel: "Titre",
      commitCommand: "Enregistrer",
      moreInformationLabel: "Informations complémentaires",
      notesLabel: "Notes",
      repeatLabel: "Répéter",
      repeatEveryLabel: "Tous les",
      endRepeatLabel: "Fin de répétition",
      never: "Jamais",
      onLabel: "Le",
      ofLabel: "De",
      weeksOnLabel: "semaine(s) le:",
      occurrencesLabel: "occurrence(s)",
      afterLabel: "Après le",
      monthly: "Mensuel",
      yearly: "Annuel",
      daily: "Journalier",
      weekly: "Hebdomadaire",
      monthsLabel: "mois",
      ofEveryMonthLabel: "de chaque mois",
      yearsLabel: "année(s)",
      everyLabel: "Tous",
      theLabel: "Le",
      firstLabel: "Premier",
      secondLabel: "Second",
      thirdLabel: "Troisième",
      fourthLabel: "Quatrième",
      firstDayOfWeek: "Premier jour de la semaine",
      lastLabel: "Dernier",
      allDayLabel: "Toute la journée",
    },
    "de-GR": {
      allDay: "Ganztägig",
      today: "Heute",
      discardButton: "Rejeter",
      confirmCancelMessage: "Rejeter les modifications non sauvegardées ?",
      confirmDeleteMessage:
        "Êtes-vous sûr de vouloir supprimer cet évènement ?",
      cancelButton: "Annuler",
      deleteButton: "Supprimer",
    },
    "en-US": {
      allDay: "All Day",
      today: "Today",
      discardButton: "Discard",
      confirmCancelMessage: "Discard unsaved changes?",
      confirmDeleteMessage: "Are you sure you want to delete this appointment?",
      cancelButton: "Cancel",
      deleteButton: "Delete",
    },
  };

  const sendNewAppointment = (added) => {
    const newAppointment = { id: uuid(), ...added };
    let newAppointmentWithoutId = { ...newAppointment };
    delete newAppointmentWithoutId.id;
    setEvent(schedulerToApi([newAppointmentWithoutId])).then((resp) => {
      const errors = resp?.msg?.errors ?? [];
      if (errors.length === 0) {
        setIsUpdatingErrorEvent(false);
        setAllAppointments([...allAppointments, newAppointment]);
      } else {
        for (const errorsKey in errors) {
          const error = errors[errorsKey];
        }
        setIsAppointmentFormDisplayed(true);
        setAppointmentFormDisplayed(newAppointment);
        setEditingAppointment(newAppointment);
        setIsUpdatingErrorEvent(true);
      }
    });
  };

  function changeAddedAppointment(addedAppointment) {
    setAddedAppointment(addedAppointment);
  }

  function changeAppointmentChanges(appointmentChanges) {
    setAppointmentChanges(appointmentChanges);
  }

  function changeEditingAppointment(editingAppointment) {
    setEditingAppointment(editingAppointment);
  }

  function commitChanges({ added, changed, deleted }) {
    let data = [];
    if (added) {
      sendNewAppointment(added);
    }
    if (changed) {
      if (isUpdatingErrorEvent) {
        sendNewAppointment({
          ...editingAppointment,
          ...changed[editingAppointment.id],
        });
      } else {
        data = allAppointments.map((appointment) => {
          if (changed[appointment.id]) {
            const updatedAppointment = schedulerToApi([
              "startDate" in changed[appointment.id] ||
              "endDate" in changed[appointment.id]
                ? {
                    startDate: new Date(appointment.startDate),
                    endDate: new Date(appointment.endDate),
                    ...changed[appointment.id],
                  }
                : { ...changed[appointment.id] },
            ])[0];
            if (
              updatedAppointment.start_date < updatedAppointment.end_date ||
              (updatedAppointment.start_date === undefined &&
                updatedAppointment.end_date === undefined)
            ) {
              updateEvent(appointment.id, updatedAppointment).then((resp) =>
                console.log(resp)
              );
              return { ...appointment, ...changed[appointment.id] };
            } else {
              onError(true);
              return { ...appointment };
            }
          }
          return appointment;
        });
        setAllAppointments(data);
      }
    }
    if (deleted !== undefined) {
      data = allAppointments.filter(
        (appointment) => appointment.id !== deleted
      );
      deleteEvent(deleted).then((resp) => console.log(resp));
      setAllAppointments(data);
    }
  }

  const StyledDiv = styled("div")({
    [`&.${classes.toolbarRoot}`]: {
      position: "relative",
    },
  });

  const StyledLinearProgress = styled(LinearProgress)(() => ({
    [`&.${classes.progress}`]: {
      position: "absolute",
      width: "100%",
      bottom: 0,
      left: 0,
    },
  }));

  const ToolbarWithLoading = ({ children, ...restProps }) => (
    <StyledDiv className={classes.toolbarRoot}>
      <Toolbar.Root {...restProps}>{children}</Toolbar.Root>
      <StyledLinearProgress className={classes.progress} />
    </StyledDiv>
  );

  const heightPlanning =
    document.getElementById("parent-scheduler-height") != null
      ? document.getElementById("parent-scheduler-height").offsetHeight
      : "auto";

  const Content = ({ children, appointmentData, ...restProps }) => (
    <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    >
      <Grid container alignItems="center">
        <Grid item xs={2} className={"Content-textCenter"}>
          <Room className={classes.icon} />
        </Grid>
        <Grid item xs={10}>
          <span>{appointmentData.address ?? appointmentData.notes}</span>
        </Grid>
      </Grid>
    </AppointmentTooltip.Content>
  );

  return (
    <>
      <Paper>
        <Scheduler
          data={allAppointments}
          firstDayOfWeek={1}
          locale={locale}
          height={heightPlanning}
        >
          <ViewState defaultCurrentViewName="Week" />

          <DayView startDayHour={9} endDayHour={18} displayName={"Jour"} />

          <WeekView
            displayName={"Semaine"}
            startDayHour={8}
            endDayHour={19}
            timeTableCellComponent={TimeTableCell}
            dayScaleCellComponent={DayScaleCell}
          />

          <MonthView displayName={"Mois"} />

          <EditingState
            onCommitChanges={commitChanges}
            addedAppointment={addedAppointment}
            onAddedAppointmentChange={changeAddedAppointment}
            appointmentChanges={appointmentChanges}
            onAppointmentChangesChange={changeAppointmentChanges}
            editingAppointment={editingAppointment}
            onEditingAppointmentChange={changeEditingAppointment}
          />

          <Toolbar
            {...(loading ? { rootComponent: ToolbarWithLoading } : null)}
          />
          <ViewSwitcher />
          <DateNavigator />
          <TodayButton messages={localizationMessages[locale]} />
          <Appointments />
          <AllDayPanel messages={localizationMessages[locale]} />
          <EditRecurrenceMenu />
          <IntegratedEditing />
          <ConfirmationDialog messages={localizationMessages[locale]} />
          <AppointmentTooltip
            showOpenButton
            showDeleteButton
            showCloseButton
            contentComponent={Content}
          />
          <AppointmentForm
            visible={isAppointmentFormDisplayed}
            appointmentData={appointmentFormDisplayed}
            basicLayoutComponent={BasicLayout}
            messages={localizationMessages[locale]}
            onVisibilityChange={setIsAppointmentFormDisplayed}
            textEditorComponent={TextEditor}
          />
          <Resources data={ressourcesScheduler} />
        </Scheduler>
      </Paper>
    </>
  );
}

export default Planning;
