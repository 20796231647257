import React from 'react';

function TopGridDisplay(props) {
    return (
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 mb-8">
            {props.children}
        </div>
    );
}

export default TopGridDisplay;