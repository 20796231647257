"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Answer = void 0;
var User_1 = require("./User");
var Base_1 = require("./Base");
var Answer = /** @class */ (function (_super) {
    __extends(Answer, _super);
    function Answer(params) {
        var _this = _super.call(this, params) || this;
        // PROPERTIES
        _this.ambassador = new User_1.User();
        var _a = params !== null && params !== void 0 ? params : {}, ambassador = _a.ambassador, form = _a.form;
        _this.ambassador = ambassador !== null && ambassador !== void 0 ? ambassador : _this.ambassador;
        _this.form = form !== null && form !== void 0 ? form : {};
        return _this;
    }
    return Answer;
}(Base_1.Base));
exports.Answer = Answer;
