import {store} from "../../store";
import {display, setArgsForComponent, setComponent, setTitle} from "../../store/displayModal/displayModal";


const openForm = ({
                      objectType,
                      objectToModify,
                      isDynamicClass = false,
                      parameters = {},
                      isNew = false,
                      title = `Nouveau ${objectType}`,
                  }) => {
    store.dispatch(setComponent("Formulaire"));
    store.dispatch(
        setArgsForComponent({
            objectToModify: isDynamicClass ? {
                objectType,
                objectData: objectToModify ?? undefined
            } : [objectToModify, parameters],
            saveFunction: isDynamicClass ? undefined : {
                function: isNew ? "submitInsert" : "submitUpdate",
                args: {objectType},
            },
        })
    );
    store.dispatch(setTitle(title));
    store.dispatch(display());
};

export default openForm
