import React from "react";

interface TitlePageProps {
  title: string;
}

function TitlePage(props: TitlePageProps) {
  return (
    <div>
      <h1 className="text-2xl font-bold tracking- mb-6 underline">{props.title}</h1>
    </div>
  );
}

export default TitlePage;