import React from "react";

function CheckBox({ id, onclick, ...args }) {
	const emtpyfunc = () => {};
	const nameId = id ?? "id";
	const clickCallBack = onclick ?? emtpyfunc;

	return (
		<>
			<label htmlFor={nameId}>
				<input
					type="checkbox"
					name={nameId}
					id={nameId}
					className="peer hidden"
					onChange={clickCallBack}
				/>
				<div className="border-4 border-gray-400 w-5 h-5 rounded peer-checked:bg-green-500 peer-checked:hidden"></div>
				<div className="peer-checked:block hidden border-4 border-green-500 w-5 h-5 rounded peer-checked:bg-green-500">
					<img
						className="scale-150 transform-gpu"
						src={
							process.env.PUBLIC_URL + "/assets/images/check.svg"
						}
						alt="check"
					/>
				</div>
			</label>
		</>
	);
}

export default CheckBox;
