import * as React from "react";
import Paper from "@mui/material/Paper";
import {
    ViewState,
    EditingState,
    IntegratedEditing,
} from "devextreme-react/scheduler";
import {
    Scheduler,
    DayView,
    Appointments,
    AppointmentForm,
    AppointmentTooltip,
    ConfirmationDialog,
} from "devextreme-react/scheduler";

import {appointments} from "./demo-data/appointments";

const BasicLayout = ({onFieldChange, appointmentData, ...restProps}) => {
    const onCustomFieldChange = (nextValue) => {
        onFieldChange({customField: nextValue});
    };

    return (
        <AppointmentForm.BasicLayout
            appointmentData={appointmentData}
            onFieldChange={onFieldChange}
            {...restProps}
        >
            <AppointmentForm.Label text="Custom Field" type="title"/>
            <AppointmentForm.TextEditor
                value={appointmentData.customField}
                onValueChange={onCustomFieldChange}
                placeholder="Custom field"
            />
        </AppointmentForm.BasicLayout>
    );
};

export default class Demo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: appointments,
            currentDate: "2018-06-27",
        };

        this.commitChanges = this.commitChanges.bind(this);
    }

    commitChanges({added, changed, deleted}) {
        this.setState((state) => {
            let {data} = state;
            if (added) {
                const startingAddedId =
                    data.length > 0 ? data[data.length - 1].id + 1 : 0;
                data = [...data, {id: startingAddedId, ...added}];
            }
            if (changed) {
                data = data.map((appointment) =>
                    changed[appointment.id]
                        ? {...appointment, ...changed[appointment.id]}
                        : appointment
                );
            }
            if (deleted !== undefined) {
                data = data.filter((appointment) => appointment.id !== deleted);
            }
            return {data};
        });
    }

    render() {
        const {currentDate, data} = this.state;

        return (
            <Paper>
                <Scheduler data={data}>
                    <ViewState currentDate={currentDate}/>
                    <EditingState onCommitChanges={this.commitChanges}/>
                    <IntegratedEditing/>
                    <DayView startDayHour={9} endDayHour={15}/>
                    <Appointments/>
                    <AppointmentTooltip showOpenButton showDeleteButton/>
                    <ConfirmationDialog/>
                    <AppointmentForm basicLayoutComponent={BasicLayout}/>
                </Scheduler>
            </Paper>
        );
    }
}
