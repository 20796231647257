import React from "react";
import {Chip, MenuItem} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


function CustomMenuItem({
                            onSelect,
                            onContextMenu,
                            value,
                            label = "",
                            chipColor = "#f2f2f2",
                            key = "",
                            ...props
                        }) {
    return (
        <>
            <MenuItem
                key={key}
                value={value}
                className={"relative !cursor-default"}
            >
                <Chip
                    key={value}
                    label={label}
                    style={{
                        backgroundColor: chipColor,
                    }}
                    onClick={onSelect}
                />
                <div className="absolute right-3 cursor-pointer">
                    <CloseIcon onClick={onContextMenu}/>
                </div>
            </MenuItem>
        </>
    );
}

export default CustomMenuItem;
