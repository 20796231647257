import React from "react";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";

function ToolCard(props) {
  library.add(far);
  library.add(fas);

  const toolName = props.toolName ?? "Tool";
  const icon =
    findIconDefinition({
      prefix: "far",
      iconName: props.icon,
    }) ??
    findIconDefinition({
      prefix: "fas",
      iconName: props.icon,
    });
  return (
    <>
      <div className="w-24 h-24 flex flex-col justify-center items-center bg-red-100 rounded-3xl">
        <FontAwesomeIcon icon={icon} size="xl" className="pb-2" />
        <div className="text-sm">{toolName}</div>
      </div>
    </>
  );
}

export default ToolCard;
