// API CALL
import {fetchAPIwithToken} from "./index";

// STORE
import {store} from "../../store";

// OBJECT TYPES
import ObjectTypes from "./ObjectTypes/index.ts";
import resultManager from "./resultManager";

// CONSTANTES
const BASE_URL = process.env.REACT_APP_API_URL;
const API_URL = `${BASE_URL}api/`;

const checkObjectType = (objectType) => {
    return Object.keys(ObjectTypes).includes(objectType);
};

// APPEL API GET
export async function getAPI({objectType, options = "", ...args}) {
    //return emptyObjectType(objectType);
    if (checkObjectType(objectType))
        try {
            const result = await fetchAPIwithToken(
                API_URL + objectType + options,
                `JWT ${store.getState().userInfos.tokenAPI}`
            );
            return resultManager(objectType, result?.result ?? []);
        } catch (error) {
            console.error(error);
            return {
                error: true,
                message: "Une erreur est survenue lors de l'accès à l'API.",
            };
        }
    return {
        error: true,
        message: "Le type d'objet demandé n'existe pas !",
    };
}

// APPEL API POST
export async function postAPI({objectType, dataToTransfert, ...args}) {
    if (checkObjectType(objectType))
        try {
            const result = await fetchAPIwithToken(
                API_URL + objectType,
                `JWT ${store.getState().userInfos.tokenAPI}`,
                "POST",
                dataToTransfert
            );
            return result?.result;
        } catch (error) {
            console.error(error);
            return {
                error: true,
                message: "Une erreur est survenue lors de l'accès à l'API.",
            };
        }
}

// APPEL API PUT
export async function putAPI({
                                 objectType,
                                 idToModify,
                                 dataToTransfert
                             }) {
    if (checkObjectType(objectType))
        try {
            const result = await fetchAPIwithToken(
                API_URL + objectType + "/" + idToModify,
                `JWT ${store.getState().userInfos.tokenAPI}`,
                "PUT",
                dataToTransfert
            );
            return result?.result;
        } catch (error) {
            console.error(error);
            return {
                error: true,
                message: "Une erreur est survenue lors de l'accès à l'API.",
            };
        }
}

// APPEL API DELETE
export async function deleteAPI({objectType, idToDelete, ...args}) {
    if (checkObjectType(objectType))
        try {
            const result = await fetchAPIwithToken(
                API_URL + objectType + "/" + idToDelete,
                `JWT ${store.getState().userInfos.tokenAPI}`,
                "DELETE"
            );
            return result?.result;
        } catch (error) {
            console.error(error);
            return {
                error: true,
                message: "Une erreur est survenue lors de l'accès à l'API.",
            };
        }
}
