import { createSlice } from '@reduxjs/toolkit'

export interface displayModalType {
    isDisplayed: boolean,
    title: string,
    component: any,
    argsForComponent: any
}

const initialDisplayModalState = {
    isDisplayed: false,
    title: "Titre par défaut!",
    component: undefined,
    argsForComponent: {}

}

export const displayModalSlice = createSlice({
    name: 'displayModal',
    initialState: initialDisplayModalState,
    reducers: {
        display: (state) => {
            state.isDisplayed = true;
        },
        hide: (state) => {
            state.isDisplayed = false;
        },
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setComponent: (state, action) => {
            state.component = action.payload;
        },
        setArgsForComponent: (state, action) => {
            state.argsForComponent = action.payload;
        }
    }
})

// Action creators are generated for each case reducer function
export const { display, hide, setComponent, setTitle, setArgsForComponent } = displayModalSlice.actions

export default displayModalSlice.reducer