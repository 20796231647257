import React, { useState } from "react";
import SmallCard from "../components/SmallCard/SmallCard";
import { getAPI } from "../services/API/requestsManager";
import TopGridDisplay from "../components/TopGridDisplay/TopGridDisplay";
// import {Users} from "../services/API/Repositories/Users";
// import {Events} from "../services/API/Repositories/Events";

function Dashboard(userName = "USER") {
  const [gotEventToday, setGotEventToday] = useState(false);
  const [formsToday, setFormsToday] = useState(0);
  const [formsPerAmbassador, setFormsPerAmbassador] = useState(0);
  const [futureEvents, setFutureEvents] = useState(0);
  const [eventThisYear, setEventThisYear] = useState(0);

  const startSchoolYear = new Date();
  startSchoolYear.setMonth(-4);
  const today = new Date();
  const todayMorning = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDay()
  );

  // GET NEXT EVENTS
  // getAPI({objectType: "events", options: "/between/" + startSchoolYear.toISOString()}).then(events => {
  //     if (!events.error) {
  //         const tomorrow = new Date(todayMorning);
  //         tomorrow.setDate(tomorrow.getDate() + 1);
  //         console.log(events);
  //         setFutureEvents(events.filter(event => new Date(event.start_date) > todayMorning).length);
  //         setEventThisYear(events.length);
  //         setGotEventToday(events.filter(event => new Date(event.start_date) >= todayMorning && new Date(event.start_date) < tomorrow).length > 0);
  //     }
  // });

  // GET NEXT EVENTS
  getAPI({
    objectType: "answers",
    options: "/between/" + todayMorning.toISOString(),
  }).then((answers) => {
    setFormsToday(answers.length);
  });

  // const users = new Users();
  // const events = new Events();
  // console.log(users.getAll().then(rt => console.log(rt)));
  // console.log(events.getAll().then(rt => console.log(rt)));

  return (
    <>
      <TopGridDisplay>
        {gotEventToday ? (
          <>
            <SmallCard
              texte="Formulaire(s) remplis aujourd'hui"
              keyFigure={formsToday}
              isEventDay={true}
            />
            <SmallCard
              texte="Formulaire(s) / Ambassador"
              keyFigure={formsPerAmbassador}
              isEventDay={true}
            />
          </>
        ) : null}
        <SmallCard
          texte="Évènement(s) à venir"
          keyFigure={futureEvents}
          isEventDay={false}
        />
        <SmallCard
          texte="Évènement(s) fait cette année"
          keyFigure={eventThisYear}
          isEventDay={false}
        />
      </TopGridDisplay>
    </>
  );
}

export default Dashboard;
