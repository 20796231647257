"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
var Base_1 = require("./Base");
var User = /** @class */ (function (_super) {
    __extends(User, _super);
    function User(params) {
        var _this = _super.call(this, params) || this;
        var _a = params !== null && params !== void 0 ? params : {}, stats = _a.stats, email = _a.email, firstname = _a.firstname, lastname = _a.lastname, tel = _a.tel, roles = _a.roles, school = _a.school;
        _this.email = email;
        _this.firstname = firstname;
        _this.lastname = lastname;
        _this.tel = tel;
        _this.roles = roles;
        _this.school = school;
        _this.stats = stats;
        _this.table = "users";
        return _this;
    }
    User.prototype.addFormFields = function (_a) {
        var objectJson = _a.objectJson, objectProps = _a.objectProps;
        objectProps.school.type = "select-one";
        objectProps.school.relatedRepo = "schools";
        objectProps.school.label = "École";
        objectProps.school.placeholder = "WebTech, ESCEN, Bachelor...";
        objectProps.school.required = true;
        objectProps.roles.type = "select-multiple";
        objectProps.roles.relatedRepo = "roles";
        objectProps.roles.label = "Rôles";
        objectProps.roles.placeholder = "Rôles";
        objectProps.roles.required = true;
        objectProps.firstname.label = "Prénom";
        objectProps.firstname.placeholder = "Prénom";
        objectProps.firstname.order = "1";
        objectProps.firstname.required = true;
        objectProps.lastname.label = "Nom";
        objectProps.lastname.placeholder = "Nom";
        objectProps.lastname.order = "2";
        objectProps.lastname.required = true;
        objectProps.stats.nb_forms = { display: false };
        objectProps.stats.nb_events = { display: false };
        objectProps.stats.level = { display: false };
        if (this._id) {
            objectProps._id = {};
            objectProps._id.type = "hidden";
            objectProps._id.value = this._id;
        }
        return { objectJson: objectJson, objectProps: objectProps };
    };
    ;
    return User;
}(Base_1.Base));
exports.User = User;
