import clsx from "clsx";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {hide} from "../../store/displayModal/displayModal";
import Button from "../Buttons/Button";

import DynamicComponent from "../DynamicComponent/DynamicComponent";

const baseModal = (dispatch) => {
    return (
        <>
            <div className="p-6 space-y-6 z-[1000]">
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    With less than a month to go before the European Union enacts new
                    consumer privacy laws for its citizens, companies around the world are
                    updating their terms of service agreements to comply.
                </p>
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    The European Union’s General Data Protection Regulation (G.D.P.R.)
                    goes into effect on May 25 and is meant to ensure a common set of data
                    rights in the European Union. It requires organizations to notify
                    users as soon as possible of high-risk data breaches that could
                    personally affect them.
                </p>
            </div>
        </>
    );
};

const baseFooter = (dispatch) => {
    return (
        <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b ">
            <Button
                type={"primary"}
                label={"I accept"}
                onclick={() => dispatch(hide())}
            />
            <Button
                type={"secondary"}
                label={"Decline"}
                onclick={() => dispatch(hide())}
            />
        </div>
    );
};

function Modal({textToShow, ...args}) {
    const isModalOpen = useSelector((state) => state.displayModal.isDisplayed);
    const componentToRender = useSelector(
        (state) => state.displayModal.component
    );
    const title = useSelector((state) => state.displayModal.title);
    const argsForComponent = useSelector(
        (state) => state.displayModal.argsForComponent
    );

    // console.log(argsForComponent);

    const dispatch = useDispatch();

    return (
        <div
            id="medium-modal"
            data-modal-placement="center"
            tabIndex="-1"
            className={clsx(
                "backdrop-blur-sm fixed top-0 left-0 right-0 z-[1000] w-full h-screen p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full flex justify-center items-center",
                isModalOpen ? "block" : "hidden"
            )}
        >
            <div className="w-full h-auto max-h-[100vh] max-w-lg">
                <div className="relative bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between p-5 border-b rounded-t">
                        <h3 className="text-xl font-medium text-gray-900">{title}</h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-hide="medium-modal"
                            onClick={() => dispatch(hide())}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {componentToRender ? (
                        <DynamicComponent is={"Formulaire"} {...argsForComponent} />
                    ) : (
                        baseModal()
                    )}

                    {/* {componentToRender
						? componentToRender()
					: baseModal(dispatch)}*/}
                    {/* {baseFooter(dispatch)} */}
                </div>
            </div>
        </div>
    );
}

export default Modal;
