"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notification = void 0;
var Base_1 = require("./Base");
var Notification = /** @class */ (function (_super) {
    __extends(Notification, _super);
    function Notification(params) {
        var _this = _super.call(this, params) || this;
        // PROPERTIES
        _this.users = [];
        _this.evenement = null;
        var _a = params !== null && params !== void 0 ? params : {}, users = _a.users, type = _a.type, subtype = _a.subtype, evenement = _a.evenement;
        _this.users = users !== null && users !== void 0 ? users : _this.users;
        _this.type = type !== null && type !== void 0 ? type : '';
        _this.subtype = subtype !== null && subtype !== void 0 ? subtype : '';
        _this.evenement = evenement !== null && evenement !== void 0 ? evenement : null;
        return _this;
    }
    return Notification;
}(Base_1.Base));
exports.Notification = Notification;
