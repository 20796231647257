// API CALL
import { fetchAPIwithToken } from "../API";

// STORE
import { store } from "../../store";

// CONSTANTES
const BASE_URL = process.env.REACT_APP_API_URL;
const API_URL = `${BASE_URL}api/`;

// Récupération de la liste des formulaires
export async function getForms() {
  try {
    const result = await fetchAPIwithToken(
      API_URL + "forms",
      `JWT ${store.getState().userInfos.tokenAPI}`
    );
    return result?.result;
  } catch (error) {
    console.error(error);
    return {
      error: true,
      message: "Une erreur est survenue lors de l'accès à l'API.",
    };
  }
}

export async function getFormsReceived() {
  try {
    const result = await fetchAPIwithToken(
      API_URL + "answers/",
      `JWT ${store.getState().userInfos.tokenAPI}`
    );
    return result?.result;
  } catch (error) {
    console.error(error);
    return {
      error: true,
      message: "Une erreur est survenue lors de l'accès à l'API.",
    };
  }
}