import { store } from "../../store";

let userInfos = {};

updateUserInfos()
store.subscribe(updateUserInfos);

function getUserInfos(state) {
    return state.userInfos;
}

function updateUserInfos() {
    userInfos = getUserInfos(store.getState());
}

export const hasAccessTo = (elementToTest = 'admin_ambassador') => {
    let hasAccess = false;
    userInfos?.roles.forEach(key => {
        if (key.access[elementToTest]) {
            hasAccess = true;
        }
    });
    return hasAccess;
};

export function hasMinimalAccess(listRoles) {
    let hasAccess = false;
    listRoles.forEach(key => {
        if (key.access["admin_ambassador"]) {
            hasAccess = true;
        }
    });
    return hasAccess;
}