export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (err) {
        // Ignore write errors.
    }
};

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        const seriaJson = JSON.parse(serializedState);
        const actualDate = new Date();
        const anteDate = new Date(actualDate);
        anteDate.setMinutes(actualDate.getMinutes() - 15);
        if (new Date(seriaJson.userInfos.lastUpdate ?? 0) < anteDate)
            return undefined;
        return seriaJson;
    } catch (err) {
        return undefined;
    }
};