import React, { useState } from "react";
import { TextField } from "@mui/material";

const AmbassadorsLimitSelector = ({
  listSchools = [],
  listAmbassadors = [],
  updateMaxPerSchool = () => {},
  maxPerSchool = {},
  ...props
}) => {
  const [state, setState] = useState({});

  const getNumberOfAmbassadorsPerSchool = (idOfSchool) => {
    return listAmbassadors.filter(
      (ambassador) => ambassador.school._id === idOfSchool
    ).length;
  };

  const generateLimitSelector = () => {
    const elementNodes = [];
    listSchools.forEach((school) => {
      if (school)
        elementNodes.push(
          <TextField
            key={"max_per_school_" + school.name}
            label={school.name}
            value={maxPerSchool[school._id]?.max ?? 0}
            onChange={(e) => updateMaxPerSchool(e.target.value, school._id)}
            type="number"
            inputProps={{
              min: 0,
              max: getNumberOfAmbassadorsPerSchool(school._id),
            }}
            fullWidth
          />
        );
    });
    return elementNodes;
  };

  const handleChange = (value, name) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <div className="flex gap-4 flex-col">
      {listSchools.length > 0 ? <h3>Maximum représentants</h3> : null}
      <div className="grid grid-cols-3 gap-2">{generateLimitSelector()}</div>
    </div>
  );
};

export default AmbassadorsLimitSelector;
