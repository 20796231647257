import React, {useEffect, useState} from 'react';
import ButtonMenu from "../components/Buttons/ButtonMenu";
import CheckBox from "../components/CheckBox/CheckBox";
import {getForms} from "../services/Forms";
import {handelDelete} from "../hooks/handleDelete";
import openForm from "../components/Formulaire/openForm";
import moment from "moment/moment";
import 'moment/locale/fr';
import {useSelector} from "react-redux";
import clsx from "clsx";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import {HubspotForms} from "../services/API/Repositories/HubspotForms";
import {useTheme} from "@mui/material";

import SyncAltIcon from '@mui/icons-material/SyncAlt';

moment.locale('fr');

function Forms(props) {
    const [formList, setFormList] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMultipleFormSelected, setIsMultipleFormSelected] = useState(false);
    const [isMenuShown, setIsMenuShown] = useState(false);
    const [hubSpotFormStatus, setHubSpotFormStatus] = useState(3);
    const theme = useTheme();

    const isModalOpen = useSelector((state) => state.displayModal.isDisplayed);

    useEffect(() => {
        getForms().then((forms) => setFormList(forms ?? []));
    }, [isModalOpen]);

    const selectFormById = (idForm) => {
        document.getElementById("form-" + idForm).checked =
            !document.getElementById("form-" + idForm).checked;
        changeSelection();
    };
    const selectAll = (ev) => {
        const isChecked = ev.target.checked;
        formList.forEach((form) => {
            document.getElementById("form-" + form._id).checked = isChecked;
        });
        changeSelection();
    };
    const getSelectedForms = () => {
        const allFormsNode = Array.from(
            document.querySelectorAll('[id^="form-"]:checked')
        );
        return allFormsNode.map((form) => {
            return (
                formList.filter((lsF) => lsF._id === form.id.replace("form-", ""))[0] ??
                null
            );
        });
    };
    const changeSelection = () => {
        setIsMultipleFormSelected(getSelectedForms().length > 1);
        setIsMenuShown(getSelectedForms().length > 0);
    };
    const openMenu = () => {
        setIsMenuOpen(true);
        window.addEventListener("click", closeMenu, true);
    };

    function closeMenu() {
        setIsMenuOpen(false);
        window.removeEventListener("click", closeMenu, true);
    }

    function getMenuItems() {
        return isMultipleFormSelected
            ? [
                {
                    name: "Supprimer",
                    type: "delete",
                    onClick: handleLocalDelete,
                },
                // {
                //     name: "Dupliquer",
                //     link: getRoutePathByName("create_new_form"),
                // },
            ]
            : [
                {
                    name: "Supprimer",
                    type: "delete",
                    onClick: handleLocalDelete,
                },

                {
                    name: "Modifier",
                    // link: getRoutePathByName("create_new_form"),
                    onClick: () => {
                        openForm({
                            objectType: "forms",
                            objectToModify: getSelectedForms()?.[0],
                            title: getSelectedForms()?.[0].name,
                            isDynamicClass: true,
                        });
                    }
                },
                // {
                //     name: "Dupliquer",
                //     // link: getRoutePathByName("create_new_form"),
                //     onClick: () => {
                //         alert("Dupliquer")
                //     }
                // },
            ];
    }

    function handleLocalDelete() {
        const idSelectedForms = getSelectedForms().map((form) => form._id);
        handelDelete(
            "forms",
            "Suppression du/des formulaire(s)",
            "Êtes-vous sûr(e) de vouloir supprimer ce ou ces formulaire(s) ?",
            idSelectedForms,
            formList,
            setFormList,
            () => setIsMenuShown(false)
        );
    }

    function generateListedForms(forms) {
        let nodeList = [];
        forms.forEach((form) => {
            nodeList.push(
                <tr
                    key={form._id}
                    className={clsx("border-b border-t-2 border-t-transparent hover:border-t-gray-400", form.isVisible ? "bg-gray-100 hover:bg-gray-200" : "bg-gray-300 hover:bg-gray-200")}
                    onClick={() => {
                        selectFormById(form._id);
                    }}
                >
                    <td className="px-6 py-4">
                        <CheckBox id={"form-" + form._id} onclick={changeSelection}/>
                    </td>
                    <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                        {form.name}
                    </th>
                    <td className="px-6 py-4">{form.school.name}</td>
                    <td className="px-6 py-4">{form.evenement.name}</td>
                    <td className="px-6 py-4" colSpan={2}>
                        {form.hubspot_form.name}
                    </td>
                    <td className="px-6 py-4">
                        {moment(form.updatedAt).format("H:mm, D MMM Y")}
                    </td>
                </tr>
            );
        });
        return nodeList;
    }

    const updateHubSpotForm = () => {
        setHubSpotFormStatus(0);
        const hubSpotRepo = new HubspotForms();
        setTimeout(() => {
            hubSpotRepo.updateDB().then(resp => {
                if (resp === "HubSpot Updated")
                    setHubSpotFormStatus(1);
                else
                    setHubSpotFormStatus(2);
                setTimeout(() => {
                    setHubSpotFormStatus(3);
                }, 2000)
            });
        }, 500);

    }

    return (
        <>
            <div>
                <LoadingButton
                    disabled={hubSpotFormStatus === 1}
                    loading={hubSpotFormStatus === 0}
                    loadingPosition="start"
                    startIcon={
                        hubSpotFormStatus === 1 ? (
                            <CheckCircleIcon/>
                        ) : hubSpotFormStatus === 2 ? (
                            <ReportGmailerrorredIcon/>
                        ) : (
                            <SyncAltIcon/>
                        )
                    }
                    onClick={updateHubSpotForm}
                    style={{
                        color: theme.palette.getContrastText("#fff"),
                    }}
                >
                    {hubSpotFormStatus === 1
                        ? "Synchronisé"
                        : hubSpotFormStatus === 2
                            ? "Erreur"
                            : "Synchroniser hubspot"}
                </LoadingButton>
            </div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-900 bg-gray-200">
                    <tr>
                        <th colSpan={6}></th>
                        <th scope="col">
                            <div className="relative inline-block text-left w-full">
                                <div className="flex justify-end">
                                    <div
                                        onClick={() => {
                                            openForm({
                                                objectType: "forms",
                                                title: "Nouveau formulaire",
                                                isDynamicClass: true,
                                                isNew: true,
                                            });
                                        }}
                                        className="align-middle pt-4 text-sm font-semibold flex justify-end cursor-pointer"
                                    >
                                        <img
                                            className="h-6 w-6"
                                            src={process.env.PUBLIC_URL + "/assets/images/add.svg"}
                                            alt=""
                                        />
                                    </div>
                                    <div className="px-6 pt-4 flex flex-col justify-center">
                                        {isMenuShown ? (
                                            <ButtonMenu
                                                menuPosition="bottom"
                                                menuItems={getMenuItems()}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr className="uppercase">
                        <th scope="col" className="px-6 py-3">
                            <CheckBox id="allSelector" onclick={selectAll}/>
                        </th>
                        <th scope="col" className="px-6 pb-3">
                            Nom
                        </th>
                        <th scope="col" className="px-6 pb-3">
                            Ecole
                        </th>
                        <th scope="col" className="px-6 pb-3">
                            Event
                        </th>
                        <th scope="col" colSpan={2} className="px-6 pb-3">
                            Formulaire HubSpot
                        </th>
                        <th scope="col" className="px-6 pb-3">
                            Dernière modification
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {generateListedForms(formList)}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Forms;
