import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "../store/userInfos/userInfosSlice";
import { Navigate } from "react-router-dom";

// IMPORTATION DES ROUTES
import { getRoutePathByName } from "../routes/routes";

function Logout() {
	// Récupération de la destination de redirection si existe
	const queryParameters = new URLSearchParams(window.location.search);
	const afterTo = queryParameters.get("afterTo");

	const dispatch = useDispatch();
	dispatch(logout());

	if (afterTo !== null) {
		return (
			<Navigate
				to={getRoutePathByName("login") + "/?afterTo=" + afterTo}
			/>
		);
	} else {
		return <Navigate to={getRoutePathByName("login")} />;
	}
}

export default Logout;
