"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Base = void 0;
var Pluralizer_1 = require("./Pluralizer");
var requestsManager_1 = require("../requestsManager");
var Base = /** @class */ (function () {
    function Base(params) {
        this.notUpdatable = ["assocLabel", "notUpdatable", "table", "_id", "updatedAt", "createdAt"];
        this.assocLabel = {};
        var _a = params !== null && params !== void 0 ? params : {}, _id = _a._id, table = _a.table, updatedAt = _a.updatedAt, createdAt = _a.createdAt;
        var tableName = this.constructor.name.charAt(0).toLowerCase() + this.constructor.name.slice(1);
        this._id = _id !== null && _id !== void 0 ? _id : null;
        this.table = table !== null && table !== void 0 ? table : (0, Pluralizer_1.Pluralizer)(tableName);
        this.updatedAt = updatedAt ? new Date(updatedAt) : null;
        this.createdAt = createdAt ? new Date(createdAt) : null;
        this.assocLabel._id = "ID";
        this.assocLabel.table = "Table";
        this.assocLabel.updatedAt = "Mise à jour";
        this.assocLabel.createdAt = "Créé";
    }
    Base.prototype.addAssocLabel = function (key, value) {
        if (key && value)
            this.assocLabel[key] = value;
    };
    Base.prototype.addNonUpdatableField = function (field) {
        this.notUpdatable.push(field);
    };
    Base.prototype.save = function (callback) {
        console.log(this.toString(["_id"], true));
        if (this._id !== null)
            return (0, requestsManager_1.putAPI)({ objectType: this.table, idToModify: this._id, dataToTransfert: this.toString(["_id"], true) })
                .then(function (response) { return callback ? callback(response) : function () {
            }; });
        else
            return (0, requestsManager_1.postAPI)({ objectType: this.table, dataToTransfert: this.toString(["_id"], true) })
                .then(function (response) { return callback ? callback(response) : function () {
            }; });
    };
    Base.prototype.delete = function (callback) {
        if (this._id !== null)
            return (0, requestsManager_1.putAPI)({ objectType: this.table, idToModify: this._id, dataToTransfert: {} })
                .then(function (response) { return callback ? callback(response) : function () {
            }; });
        else
            return { error: true, message: "Not in database to be deleted." };
    };
    Base.prototype.toString = function (fieldsToIgnore, getOnlyIdsOfObject) {
        if (fieldsToIgnore === void 0) { fieldsToIgnore = []; }
        if (getOnlyIdsOfObject === void 0) { getOnlyIdsOfObject = false; }
        var objectJson = JSON.parse(JSON.stringify(this));
        fieldsToIgnore.forEach(function (field) { return delete objectJson[field]; });
        this.notUpdatable.forEach(function (field) { return delete objectJson[field]; });
        for (var _i = 0, _a = Object.entries(objectJson); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (value === undefined || value === "" || value === null)
                delete objectJson[key];
            else if (typeof value === "object") {
                if (Object.keys(value).length === 0)
                    delete objectJson[key];
                var valueCasted = value;
                if (getOnlyIdsOfObject && valueCasted._id !== undefined)
                    objectJson[key] = valueCasted._id;
            }
        }
        return objectJson;
    };
    Base.prototype.getFormFields = function () {
        var _this = this;
        var objKeys = Object.entries(this);
        var objectJson = {};
        var objectProps = {};
        objKeys.forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (!_this.notUpdatable.includes(key)) {
                objectJson[key] = value;
                objectProps[key] = { label: _this.assocLabel[key] };
            }
        });
        this.addFormFields({ objectJson: objectJson, objectProps: objectProps });
        return [objectJson, objectProps];
    };
    Base.prototype.addFormFields = function (_a) {
        var objectJson = _a.objectJson, objectProps = _a.objectProps;
        return { objectJson: objectJson, objectProps: objectProps };
    };
    ;
    return Base;
}());
exports.Base = Base;
