import {Answer} from "./Answer";
import {Field} from "./Field";
import {Form} from "./Form";
import {Role} from "./Role";
import {School} from "./School";
import {TypeField} from "./TypeField";
import {User} from "./User";
import {Ambassador} from "./Ambassador";
import {Evenement} from "./Evenement";
import {File} from "./File";
import {HubspotForm} from "./HubSpotForm";
import {Notification} from "./Notification";

export default {
    ambassadors: Ambassador,
    answers: Answer,
    fields: Field,
    files: File,
    forms: Form,
    hubspotForms: HubspotForm,
    notifications: Notification,
    roles: Role,
    schools: School,
    typeFields: TypeField,
    users: User,
    events: Evenement
}
