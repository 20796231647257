// Importation des pages
import NoPage from "../pages/NoPage";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import Dashboard from "../pages/Dashboard";
import Forms from "../pages/Forms";
import Events from "../pages/Events";
import Inbox from "../pages/Inbox";
import Roles from "../pages/Roles";
import Users from "../pages/Users";
import Customization from "../pages/Customization";
import Ambassadors from "../pages/Ambassadors";
import Navigation from "../components/Navigation/Navigation"
import BuildingPage from "../pages/Building";

// Formulaires
import createurFormulaire from "../pages/Formulaire/CreateurFormulaire";
import AmbassadorAttachments from "../pages/AmbassadorAttachments";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import MyInformation from "../pages/Parameters/MyInformation";
import {Link} from "react-router-dom";
import React from "react";

const routeAssoc = [];

export const routes = {
    login: {
        path: "/login",
        title: "Connexion",
        component: Login
    },
    logout: {
        path: "/logout",
        title: "Déconnexion",
        component: Logout
    },
    admin_ambassadors: {
        security: "admin_ambassador",
        title: "Admin ambassador",
        path: "/ambassadors",
        component: Navigation,
        accessible: false,
        children: {
            dashboard: {
                path: "/dashboard",
                title: "Tableau de bord",
                component: Dashboard
            },
            forms_path: {
                path: "/formulaires",
                accessible: false,
                children: {
                    forms: {
                        path: "/",
                        title: "Formulaires",
                        component: Forms,
                    },
                    create_new_form: {
                        path: "/nouveau_formulaire",
                        title: "Nouveau formulaire",
                        component: createurFormulaire,
                    },
                }
            },
            events: {
                path: "/evenements",
                title: "Évènements",
                component: Events
            },
            ambassadors: {
                path: "/ambassadors",
                title: "Ambassadors",
                component: Ambassadors
            },
            ambassadors_attachments: {
                path: "/ambassadors_attachments",
                title: "Fichiers utiles",
                component: AmbassadorAttachments
            },
            inbox: {
                path: "/inbox",
                title: "Boîte de réception",
                component: Inbox
            }
        }
    },
    admin_users: {
        security: "admin_users",
        path: "/administration",
        component: Navigation,
        title: "Administration utilisateur",
        accessible: false,
        children: {
            roles: {
                path: "/roles",
                title: "Rôles",
                component: Roles
            },
            users: {
                path: "/users",
                title: "Utilisateurs",
                component: Users
            },
            customization: {
                path: "/customization",
                title: "Personnalisation",
                component: Customization
            }
        }
    },
    parameters: {
        path: "/parameters",
        title: "Paramètres",
        component: Navigation,
        accessible: false,
        children: {
            "my-infos": {
                path: "/my-infos",
                title: "Modifier mes informations",
                component: MyInformation
            },
            security: {
                path: "/security",
                title: "Sécurité",
                component: BuildingPage
            },
            notification: {
                path: "/notification",
                title: "Notifications",
                component: BuildingPage
            },
            help: {
                path: "/help",
                title: "Aide",
                component: BuildingPage
            },
            about: {
                path: "/about",
                title: "A propos",
                component: BuildingPage
            }
        }
    },
    privacy: {
        path: "/privacy",
        title: "Politique de confidentialité",
        component: PrivacyPolicy
    },
    error404: {
        path: "*",
        title: "Page non existante",
        component: NoPage,
    },

}

function getArrayOfRoute(mainRoutes, basePath = "") {
    const test = [];
    for (const [nameRoute, infosRoute] of Object.entries(mainRoutes)) {
        if (infosRoute.children !== undefined)
            test.push(...getArrayOfRoute(infosRoute.children, basePath + infosRoute.path));
        test.push([nameRoute, {...infosRoute, path: basePath + infosRoute.path}])
    }
    return test;
}

routeAssoc.push(...getArrayOfRoute(routes));

export function getRoutePathByName(name) {
    return routeAssoc.filter(route => route[0] === name)[0][1].path;
}

export function getNameByRoutePath(path) {
    const rte = routeAssoc.filter(route => route[1].path === path)[0];
    if (rte !== undefined)
        return rte[0];
    else
        return "dashboard";
}

export function getTitleByRouteName(name) {
    return routeAssoc.filter(route => route[0] === name)[0][1].title;
}

export function getAccessibilityByRouteName(name) {
    return routeAssoc.filter(route => route[0] === name)?.[0]?.[1]?.accessible ?? null;
}

export function getBreadcrumbByRouteName(name) {
    const pathParts = getRoutePathByName(name)?.split('/').filter((part) => part?.trim() !== '');
    const breadcrumbs = pathParts?.map((part, partIndex) => {
        const previousParts = pathParts.slice(0, partIndex);
        return {
            label: part,
            href: previousParts?.length > 0 ? `/${previousParts?.join('/')}/${part}` : `/${part}`,
            accessible: getAccessibilityByRouteName(part) ?? false
        };
    }) || [];

    // return test;
    return breadcrumbs.map((breadcrumb, index) => <>
        {/*<a href={breadcrumb?.href}>{breadcrumb?.label}</a>{" / "}*/}
        <span className="self-center text-l font-semibold whitespace-nowrap text-gray-700">
              <Link to={breadcrumb?.href}>
                {breadcrumb?.label}
              </Link>
        </span>
        {breadcrumbs.length !== index + 1 &&
            <span className="self-center text-l font-semibold whitespace-nowrap text-gray-700">
              {" / "}
            </span>
        }
    </>);
}
