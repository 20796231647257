import mapKey from "./mapKey";

const apiToScheduler = (events) => {
    return events.map((ev) => translateApiToScheduler(ev))
}

const translateApiToScheduler = (obj) => {
    const newObject = {};
    for (const [key, value] of Object.entries(obj)) {
        newObject[getKeyByNom(mapKey, key) ?? key] = value ?? mapKey[getKeyByNom(mapKey, key)]?.default ?? "";
    }
    return newObject
}

function getKeyByNom(object, nom) {
    return Object.keys(object).find(key => object[key].nom === nom);
}

export default apiToScheduler
