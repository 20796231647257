// REDUX
import {store} from "../../store";
import {setUserInfos} from "../../store/userInfos/userInfosSlice";

import {insertUser, updateUser} from "../../services/Users";
import {postAPI, putAPI} from "../../services/API/requestsManager";

function genPassword() {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 12;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
        let randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password
}

const submitUserInfos = ({_id, ...jsonData}) => {
    const CURRENT_USER = store.getState().userInfos;
    if (CURRENT_USER.id === _id)
        store.dispatch(setUserInfos({...CURRENT_USER, ...jsonData}));
    updateUser(_id, jsonData).then(res => {
        console.log(res);
    });
}

const submitInsertUserInfos = async ({_id, ...jsonData}) => {
    jsonData.password = genPassword();

    return await insertUser(jsonData);
}

const submitInsert = async ({objectType, _id, ...jsonData}) => {
    if (objectType === "users") {
        return await submitInsertUserInfos({_id, ...jsonData});
    } else {
        return await postAPI({objectType, dataToTransfert: jsonData});
    }
}

const submitUpdate = async ({objectType, _id, ...jsonData}) => {
    const res = await putAPI({objectType, idToModify: _id, dataToTransfert: jsonData});
    return res;
}

export {submitUserInfos, submitInsertUserInfos, submitInsert, submitUpdate};
