"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DynamicClass = exports.Store = void 0;
var Answer_1 = require("./Answer");
var Field_1 = require("./Field");
var File_1 = require("./File");
var Form_1 = require("./Form");
var Role_1 = require("./Role");
var School_1 = require("./School");
var TypeField_1 = require("./TypeField");
var User_1 = require("./User");
var Ambassador_1 = require("./Ambassador");
var Evenement_1 = require("./Evenement");
var HubSpotForm_1 = require("./HubSpotForm");
var Notification_1 = require("./Notification");
exports.Store = {
    ambassador: Ambassador_1.Ambassador,
    answers: Answer_1.Answer,
    fields: Field_1.Field,
    files: File_1.File,
    forms: Form_1.Form,
    hubspotForm: HubSpotForm_1.HubspotForm,
    notifications: Notification_1.Notification,
    roles: Role_1.Role,
    schools: School_1.School,
    typeFields: TypeField_1.TypeField,
    users: User_1.User,
    events: Evenement_1.Evenement,
};
var DynamicClass = /** @class */ (function () {
    function DynamicClass(className, opts) {
        if (exports.Store[className] === undefined || exports.Store[className] === null) {
            throw new Error("Class type of '".concat(className, "' is not in the store"));
        }
        return new exports.Store[className](opts);
    }
    return DynamicClass;
}());
exports.DynamicClass = DynamicClass;
