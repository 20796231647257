import {BrowserRouter as Router, Routes, Route, Outlet} from "react-router-dom";
import './App.css';

// IMPORTATION DES ROUTES
import {routes} from "./routes/routes";

// IMPORTATION REDUX
import {useSelector} from "react-redux";

import {hasAccessTo} from "./services/AccessManager/AccessManager";
import {TypeField} from "./services/API/ObjectTypes/TypeField.ts";

function App() {

    // MODIFIE LE 12 MAI
    //useSelector((state) => state);

    function generationRoutes(routesToGenerate, baseUrl = "") {
        const listRoutes = []
        // eslint-disable-next-line
        for (const [_, route] of Object.entries(routesToGenerate)) {
            // GESTION DE SECURITE
            if (route.security !== undefined) {
                if (hasAccessTo(route.security)) {
                    if (route.children !== undefined) {
                        listRoutes.push(
                            <Route key={baseUrl + route.path} path={baseUrl + route.path}
                                   element={route.component ? <route.component/> : <Outlet/>}>
                                {generationRoutes(route.children, baseUrl + route.path)}
                            </Route>
                        )
                    } else {
                        listRoutes.push(<Route key={baseUrl + route.path} path={baseUrl + route.path}
                                               element={route.component ? <route.component/> : <Outlet/>}/>)
                    }
                }
            } else {
                if (route.children !== undefined) {
                    listRoutes.push(
                        <Route key={baseUrl + route.path} path={baseUrl + route.path}
                               element={route.component ? <route.component/> : <Outlet/>}>
                            {generationRoutes(route.children, baseUrl + route.path)}
                        </Route>
                    )
                } else {
                    listRoutes.push(<Route key={baseUrl + route.path} path={baseUrl + route.path}
                                           element={route.component ? <route.component/> : <Outlet/>}/>)
                }
            }

        }
        return listRoutes
    }

    return (
        <Router>
            <Routes>
                {generationRoutes(routes, "")}
            </Routes>
        </Router>
    )
}

export default App;
