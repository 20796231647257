import React from 'react';
import {Typography} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import {Evenement} from "../../../services/API/ObjectTypes/Evenement";
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

function EventPreview({event}: { event: Evenement | undefined }) {
    let ambassadorValidated: number = 0;
    let ambassadorSigned: number = 0;
    if (event?.delegate_per_school) {
        for (const [_, value] of Object.entries(event?.delegate_per_school)) {
            ambassadorValidated += (value?.ambassadors?.filter((amb: any) => amb.status === 1)?.length ?? 0);
            ambassadorSigned += (value?.ambassadors?.filter((amb: any) => amb.status < 2)?.length ?? 0);
        }
    }
    return (
        <>
            <Typography variant={"caption"}
                        style={{alignItems: "center", display: "flex", gap: "8px", paddingTop: '5px'}}
                        color={"text.secondary"}>
                <PushPinIcon/>{event?.address}
            </Typography>
            <Typography variant={"caption"}
                        style={{alignItems: "center", display: "flex", gap: "8px", paddingTop: '5px'}}
                        color={"text.secondary"}>
                <WorkHistoryIcon/>{event?.rdv_address + " à " + event?.rdv_date?.getHours() + "h" + event?.rdv_date?.getMinutes()}
            </Typography>
            <Typography variant={"caption"}
                        style={{alignItems: "center", display: "flex", gap: "8px", paddingTop: '5px'}}
                        color={"text.secondary"}>
                <PeopleAltIcon/>{ambassadorSigned} ambassadors inscrits dont {ambassadorValidated} validés.
            </Typography>
        </>

    );
}

export default EventPreview;
