import mapKey from "./mapKey";

const schedulerToApi = (events) => {
    return events.map((ev) => translateSchedulerToApi(ev));
}

const translateSchedulerToApi = (obj) => {
    const newObject = {};
    for (const [key, value] of Object.entries(obj)) {
        if (Array.isArray(value))
            newObject[mapKey[key]?.nom ?? key] = value.filter(val => val !== undefined)
        else
            newObject[mapKey[key]?.nom ?? key] = value ?? mapKey[key]?.default ?? "";
    }
    return newObject
}

export default schedulerToApi
