import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import NextULogo from "../assets/images/logo-seul-tampon.svg";
// IMPORTATION DES ROUTES
import { getRoutePathByName } from "../routes/routes";

// Fonction d'identification
import { login } from "../services/Users";

const Login = () => {
  // Récupération de la destination de redirection si existe
  const queryParameters = new URLSearchParams(window.location.search);
  const afterTo = queryParameters.get("afterTo");

  // VARIABLES DE STATE
  const navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [visibilityPasswords, setVisibilityPasswords] = useState(false);

  const handleSubmit = async (event) => {
    // ENVOI DU FORMULAIRE DE CONNEXION
    event.preventDefault();
    const userInfo = await login(loginEmail, loginPassword);

    if (userInfo.error === true) {
      alert(userInfo.message);
    } else {
      navigate(getRoutePathByName(afterTo ?? "dashboard"));
    }
  };

  const toogleVisibilityPass = () => {
    setVisibilityPasswords(!visibilityPasswords);
  };

  return (
    <div className="bg-gray-300 flex flex-col items-center justify-center min-h-screen md:py-2">
      <main className="flex items-center justify-center w-full px-2">
        <div className="flex flex-col w-full md:w-1/4 items-center transition duration-1000 ease-out">
          <img src={NextULogo} width={200} height={200} alt="Logo-Next-U" />
          <h3 className="text-2xl w-full font-semibold text-gray-700 pt-2 mb-3">
            Compte de
          </h3>
          {/* Inputs */}
          <form onSubmit={handleSubmit} className="w-full">
            <div className="py-2">
              <span className="px-1 text-sm text-gray-600">E-Mail</span>
              <div className="relative">
                <input
                  placeholder=""
                  type="text"
                  className="text-md block px-3 py-2 rounded-lg w-full 
					bg-white border-gray-300 shadow-sm
					focus:placeholder-gray-500
					focus:bg-white 
					focus:border-gray-600  
					focus:outline-none"
                  onChange={(e) => setLoginEmail(e.target.value)}
                  value={loginEmail}
                ></input>
              </div>
            </div>

            <div className="py-2">
              <span className="px-1 text-sm text-gray-600">Mot de passe</span>
              <div className="relative">
                <input
                  placeholder=""
                  type={visibilityPasswords ? "text" : "password"}
                  className="text-md block px-3 py-2 rounded-lg w-full 
									bg-white border-gray-300 shadow-sm
									focus:placeholder-gray-500
									focus:bg-white 
									focus:border-gray-600  
									focus:outline-none"
                  onChange={(e) => setLoginPassword(e.target.value)}
                  value={loginPassword}
                ></input>
                <FontAwesomeIcon
                  className="absolute right-4 top-1/3"
                  icon={visibilityPasswords ? faEyeSlash : faEye}
                  onClick={toogleVisibilityPass}
                />
              </div>
            </div>
            <input
              className="w-full mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              type="submit"
              value="Connexion"
            />
          </form>
        </div>
      </main>
    </div>
  );
};

export default Login;
