"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Form = void 0;
var Base_1 = require("./Base");
var Form = /** @class */ (function (_super) {
    __extends(Form, _super);
    function Form(props) {
        var _this = _super.call(this, props) || this;
        var _a = props !== null && props !== void 0 ? props : {}, school = _a.school, hubspot_form = _a.hubspot_form, name = _a.name, evenement = _a.evenement, isVisible = _a.isVisible;
        _this.school = school;
        _this.hubspot_form = hubspot_form;
        _this.name = name;
        _this.evenement = evenement;
        _this.isVisible = isVisible;
        _this.table = "forms";
        return _this;
    }
    Object.defineProperty(Form.prototype, "forLabel", {
        get: function () {
            return { label: this.name, value: this._id };
        },
        enumerable: false,
        configurable: true
    });
    Form.prototype.addFormFields = function (_a) {
        var objectJson = _a.objectJson, objectProps = _a.objectProps;
        objectProps.hubspot_form.type = "select-one";
        objectProps.hubspot_form.order = "2";
        objectProps.hubspot_form.relatedRepo = "hubspotForms";
        objectProps.hubspot_form.label = "Formulaire hubspot";
        objectProps.hubspot_form.placeholder = "Choisissez un formulaire";
        objectProps.hubspot_form.required = true;
        objectProps.school.type = "select-one";
        objectProps.hubspot_form.order = "4";
        objectProps.school.relatedRepo = "schools";
        objectProps.school.label = "École";
        objectProps.school.placeholder = "WebTech, ESCEN, Bachelor...";
        objectProps.school.required = true;
        objectProps.evenement.type = "select-one";
        objectProps.evenement.order = "3";
        objectProps.evenement.relatedRepo = "events";
        objectProps.evenement.label = "Évènement";
        objectProps.evenement.placeholder = "JPO, Salon de l'étudiant, intervention écoles...";
        objectProps.evenement.required = true;
        objectProps.isVisible.type = "checkbox";
        objectProps.isVisible.label = "Visible par les ambassadors";
        objectProps.name.label = "Nom";
        objectProps.name.order = "1";
        objectProps.name.placeholder = "Formulaire JPO NEXT-U";
        objectProps.name.required = true;
        // console.log(this._id)
        if (this._id) {
            objectProps._id = {};
            objectProps._id.type = "hidden";
            objectProps._id.value = this._id;
        }
        return { objectJson: objectJson, objectProps: objectProps };
    };
    ;
    return Form;
}(Base_1.Base));
exports.Form = Form;
