// API CALL
import {fetchAPIwithToken} from "../API";

// STORE
import {store} from "../../store";

// CONSTANTES
const BASE_URL = process.env.REACT_APP_API_URL;
const API_URL = `${BASE_URL}api/`;

// Récupération de la liste des ambassadorulaires
export async function getEvents() {
    try {
        const result = await fetchAPIwithToken(
            API_URL + "events",
            `JWT ${store.getState().userInfos.tokenAPI}`
        );
        const evTraits = result?.result.map((ev) => {
            return {
                ...ev,
                id: ev._id,
                title: ev.name,
                start_date: new Date(ev.start_date),
                end_date: new Date(ev.end_date)
            }
        })
        console.log("result", evTraits);
        return evTraits;
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message: "Une erreur est survenue lors de l'accès à l'API.",
        };
    }
}

export async function setEvent(eventObject) {
    try {
        const result = await fetchAPIwithToken(
            API_URL + "events",
            `JWT ${store.getState().userInfos.tokenAPI}`,
            "POST", eventObject
        );
        return result?.result ?? result;
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message: "Une erreur est survenue lors de l'accès à l'API.",
        };
    }
}

export async function deleteEvent(eventID) {
    try {
        const result = await fetchAPIwithToken(
            API_URL + "events/" + eventID,
            `JWT ${store.getState().userInfos.tokenAPI}`,
            "DELETE"
        );
        return result?.result ?? result;
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message: "Une erreur est survenue lors de l'accès à l'API.",
        };
    }
}


export async function updateEvent(eventID, alterationToEvent) {
    try {
        const result = await fetchAPIwithToken(
            API_URL + "events/" + eventID,
            `JWT ${store.getState().userInfos.tokenAPI}`,
            "PUT", alterationToEvent
        );
        return result?.result ?? result;
    } catch (error) {
        console.error(error);
        return {
            error: true,
            message: "Une erreur est survenue lors de l'accès à l'API.",
        };
    }
}
