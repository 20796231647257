"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DynamicRepo = exports.StoreRepo = void 0;
// import {Fields} from "./Fields";
var Files_1 = require("./Files");
// import {Forms} from "./Forms";
var Roles_1 = require("./Roles");
var Schools_1 = require("./Schools");
var Users_1 = require("./Users");
var Ambassadors_1 = require("./Ambassadors");
var Events_1 = require("./Events");
var HubspotForms_1 = require("./HubspotForms");
var Notifications_1 = require("./Notifications");
exports.StoreRepo = {
    ambassador: Ambassadors_1.Ambassadors,
    // fields: Fields,
    files: Files_1.Files,
    // forms: Forms,
    hubspotForms: HubspotForms_1.HubspotForms,
    notifications: Notifications_1.Notifications,
    roles: Roles_1.Roles,
    schools: Schools_1.Schools,
    users: Users_1.Users,
    events: Events_1.Events,
};
var DynamicRepo = /** @class */ (function () {
    function DynamicRepo(className, opts) {
        if (exports.StoreRepo[className] === undefined || exports.StoreRepo[className] === null) {
            throw new Error("Class type of '".concat(className, "' is not in the repo-store"));
        }
        return new exports.StoreRepo[className](opts);
    }
    return DynamicRepo;
}());
exports.DynamicRepo = DynamicRepo;
