import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { getRoles } from "../services/Roles/Roles.js";

// BUTTONS
import ButtonAdd from "../components/Buttons/ButtonAdd";

import SmallCard from "../components/SmallCard/SmallCard.jsx";
import MediumCard from "../components/MediumCard/MediumCard.jsx";

import { faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  setComponent,
  setArgsForComponent,
  setTitle,
  display,
} from "../store/displayModal/displayModal";
import { handelDelete } from "../hooks/handleDelete.js";
import openForm from "../components/Formulaire/openForm.js";
import TopGridDisplay from "../components/TopGridDisplay/TopGridDisplay";

const parameterForm = {
  name: {
    label: "Nom",
    order: 0,
  },
  users: { display: false },
  access: {
    app: {
      label: "Application mobile",
      type: "select-one",
      placeholder: "Choisissez",
      options: [
        { label: "Oui", value: true },
        { label: "Non", value: false },
      ],
    },
    admin_ambassador: {
      label: "Administration des ambassadors",
      type: "select-one",
      placeholder: "Choisissez",
      options: [
        { label: "Oui", value: true },
        { label: "Non", value: false },
      ],
    },
    admin_users: {
      label: "Administration des utilisateurs",
      type: "select-one",
      placeholder: "Choisissez",
      options: [
        { label: "Oui", value: true },
        { label: "Non", value: false },
      ],
    },
    admin_full: {
      label: "Administration complète",
      type: "select-one",
      placeholder: "Choisissez",
      options: [
        { label: "Oui", value: true },
        { label: "Non", value: false },
      ],
    },
  },
  createdAt: {
    display: false,
  },
  updatedAt: {
    display: false,
  },
};

function Roles() {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.displayModal.isDisplayed);

  const [listRoles, setListRoles] = useState([]);

  useEffect(() => {
    getRoles().then((roles) => {
      setListRoles(roles);
    });
  }, [isModalOpen]);

  const paramsOpenModal = {
    parameters: parameterForm,
    objectType: "roles",
  };

  const localHandelDelete = (roleToDelete) => {
    handelDelete(
      "roles",
      `${roleToDelete.name}`,
      "Êtes-vous sûr de vouloir supprimer ce rôle ?",
      roleToDelete,
      listRoles,
      setListRoles
    );
  };

  const newRole = {
    name: "",
    access: {
      app: false,
      admin_ambassador: false,
      admin_users: false,
      admin_full: false,
    },
  };

  const generateRolesCards = (roles) => {
    return roles.map((role) => {
      return (
        <MediumCard
          icon={faUserGroup}
          key={role._id}
          titre={role.name}
          textes={[role.users + " utilisateurs"]}
          menu={[
            {
              name: "Supprimer",
              link: "#",
              type: "delete",
              onClick: () => localHandelDelete(role),
            },
            {
              name: "Modifier",
              link: "#",
              type: "normal",
              onClick: () =>
                openForm({
                  ...paramsOpenModal,
                  objectToModify: role,
                  title: role.name,
                }),
            },
          ]}
        />
      );
    });
  };

  return (
    <>
      <TopGridDisplay>
        <SmallCard
          texte="Roles"
          keyFigure={listRoles.length}
          icon={faUserGroup}
        />
      </TopGridDisplay>
      <div className="flex flex-row justify-end -mb-12 -translate-y-12">
        <ButtonAdd
          onclick={() => {
            openForm({
              ...paramsOpenModal,
              objectToModify: newRole,
              title: "Nouveau rôle",
              isNew: true,
            });
          }}
        />
      </div>
      <TopGridDisplay>{generateRolesCards(listRoles)}</TopGridDisplay>
    </>
  );
}

export default Roles;
