"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Evenement = void 0;
var Base_1 = require("./Base");
var Evenement = /** @class */ (function (_super) {
    __extends(Evenement, _super);
    // name: string | undefined;
    function Evenement(props) {
        var _this = _super.call(this, props) || this;
        var _a = props !== null && props !== void 0 ? props : {}, name = _a.name, start_date = _a.start_date, end_date = _a.end_date, description = _a.description, address = _a.address, rdv_address = _a.rdv_address, rdv_date = _a.rdv_date, credits = _a.credits, represented_school = _a.represented_school, delegate_per_school = _a.delegate_per_school, allDay = _a.allDay;
        _this.name = name;
        _this.start_date = start_date ? new Date(start_date) : start_date;
        _this.end_date = end_date ? new Date(end_date) : end_date;
        _this.description = description;
        _this.address = address;
        _this.rdv_date = rdv_date ? new Date(rdv_date) : rdv_date;
        _this.rdv_address = rdv_address;
        _this.represented_school = represented_school;
        _this.delegate_per_school = delegate_per_school;
        _this.credits = credits !== null && credits !== void 0 ? credits : 0;
        _this.allDay = allDay !== null && allDay !== void 0 ? allDay : false;
        return _this;
    }
    Object.defineProperty(Evenement.prototype, "date_start_date_only", {
        get: function () {
            var _a;
            return (_a = this.start_date) === null || _a === void 0 ? void 0 : _a.toISOString().split("T")[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Evenement.prototype, "get_end_date_only", {
        get: function () {
            var _a;
            return (_a = this.end_date) === null || _a === void 0 ? void 0 : _a.toISOString().split("T")[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Evenement.prototype, "formatCalendar", {
        get: function () {
            var _a;
            var returnNonEmpty = function (value) {
                if (value !== "")
                    return value;
                return undefined;
            };
            return {
                event_id: this._id,
                title: this.name,
                start: this.start_date,
                end: this.end_date,
                description: this.description,
                address: this.address,
                rdv_address: this.rdv_address,
                rdv_date: (_a = returnNonEmpty(this.rdv_date)) !== null && _a !== void 0 ? _a : new Date(),
                represented_school: this.represented_school,
                delegate_per_school: this.delegate_per_school,
                credits: this.credits,
                allDay: this.allDay,
                color: "#0086CC",
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Evenement.prototype, "forLabel", {
        get: function () {
            return { label: this.name, value: this._id };
        },
        enumerable: false,
        configurable: true
    });
    return Evenement;
}(Base_1.Base));
exports.Evenement = Evenement;
