"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.HubspotForm = void 0;
var Base_1 = require("./Base");
var HubspotForm = /** @class */ (function (_super) {
    __extends(HubspotForm, _super);
    function HubspotForm(props) {
        var _this = _super.call(this, props) || this;
        var _a = props !== null && props !== void 0 ? props : {}, name = _a.name, portalId = _a.portalId, guid = _a.guid, methode = _a.methode, isVisible = _a.isVisible, formFieldGroups = _a.formFieldGroups;
        _this.name = name;
        _this.portalId = portalId;
        _this.guid = guid;
        _this.methode = methode;
        _this.isVisible = isVisible;
        _this.formFieldGroups = formFieldGroups;
        return _this;
    }
    Object.defineProperty(HubspotForm.prototype, "forLabel", {
        get: function () {
            return { label: this.name, value: this._id };
        },
        enumerable: false,
        configurable: true
    });
    return HubspotForm;
}(Base_1.Base));
exports.HubspotForm = HubspotForm;
