import React from "react";

function Button({ label, onclick, type, className, ...args }) {
  const getStyleForBtn = () => {
    let styles =
      "focus:ring-4 focus:outline-none font-medium rounded-lg px-5 py-2.5 text-sm";
    switch (type) {
      case "primary":
        return (
          styles +
          " text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-300"
        );
      case "secondary":
        return (
          styles +
          " text-gray-500 bg-white hover:bg-gray-100 focus:ring-gray-200 border border-gray-200 hover:text-gray-900 focus:z-10"
        );
      case "delete":
        break;

      default:
        break;
    }
  };

  return (
    <button
      data-modal-hide="medium-modal"
      type="button"
      className={getStyleForBtn() + " " + className}
      onClick={onclick ? onclick : () => {}}
    >
      {label ?? "I accept"}
    </button>
  );
}

export default Button;
