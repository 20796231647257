import React, {useState, useEffect} from "react";
// import clsx from "clsx";

import {useDispatch} from "react-redux";
import {
    display,
    setArgsForComponent,
    setComponent,
    setTitle,
} from "../store/displayModal/displayModal";

// CARDS
import SmallCard from "../components/SmallCard/SmallCard";
import MediumCard from "../components/MediumCard/MediumCard";

// ICONS
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {getAmbassadors} from "../services/Ambassadors/Ambassadors.js";

// BUTTONS
import ButtonAdd from "../components/Buttons/ButtonAdd";
import sendEmail from "../services/Mail/index.js";

// COMFIRMATION BOX
import {confirmAlert} from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {deleteAPI, getAPI} from "../services/API/requestsManager";
import openForm from "../components/Formulaire/openForm";
import TopGridDisplay from "../components/TopGridDisplay/TopGridDisplay";
import {Users} from "../services/API/Repositories/Users";

const handelDelete = (userToDelete) => {
    const options = {
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        customUI: ({onClose}) => {
            return (
                <div className="bg-gray-200 px-6 py-4 rounded-xl">
                    <h1 className="text-xl text-center mb-4">
                        Supprimer{" "}
                        <span className="text-red-600 font-bold">
              {userToDelete.lastname} {userToDelete.firstname}
            </span>
                    </h1>
                    <p className="text-base pb-3">
                        Êtes-vous sûr de vouloir supprimer cette utilisateur ?
                    </p>
                    <div className="flex gap-4">
                        <button
                            className="shadow rounded px-3 py-1 bg-gray-300"
                            onClick={onClose}
                        >
                            Annuler
                        </button>
                        <button
                            className="shadow rounded px-3 py-1 bg-red-600 text-white"
                            onClick={() => {
                                handelDeleteConfirmed(userToDelete._id);
                                onClose();
                            }}
                        >
                            Supprimer
                        </button>
                    </div>
                </div>
            );
        },
    };
    confirmAlert(options);
};

const handelDeleteConfirmed = (idToDelete) => {
    alert(idToDelete);
    //deleteAPI({ objectType: "user", idToDelete });
};

function Ambassadors() {
    const dispatch = useDispatch();
    const [ambassadorList, setAmbassadorList] = useState([]);

    const parameterForm = {
        firstname: {label: "Prénom", placeholder: "Prénom"},
        lastname: {label: "Nom", placeholder: "Nom"},
        email: {label: "E-Mail", placeholder: "E-Mail"},
        tel: {label: "Téléphone", placeholder: "Téléphone"},
        school: {
            label: "Ecole",
            placeholder: "Ecole",
            type: "select-one",
            options: [],
        },
    };

    const paramsOpenModal = {
        parameters: parameterForm,
        objectType: "users",
    };

    getAPI({objectType: "schools"}).then(
        (res) =>
            (paramsOpenModal.parameters.school.options = res.map((school) => {
                return {label: school.name, value: school._id};
            }))
    );

    useEffect(() => {
        (new Users()).getWithParams("role", ["Ambassador"]).then(ambs => {
            if (Array.isArray(ambs))
                setAmbassadorList(ambs);
            else
                setAmbassadorList([]);
        })
    }, []);

    const generateUsersCards = (users) => {
        const lstUserCard = [];
        users.forEach((user) => {
            const {_id, firstname, lastname, email, tel, school} = user;
            const optiUser = {_id, firstname, lastname, email, tel, school};
            lstUserCard.push(
                <MediumCard
                    icon={faUser}
                    key={user._id}
                    titre={user.lastname + " " + user.firstname}
                    textes={[
                        user.school.name,
                        user.stats.nb_events + " évènement(s) fait",
                        user.stats.nb_forms + " formulaires remplis",
                    ]}
                    menu={[
                        {
                            name: "Supprimer",
                            link: "#",
                            type: "delete",
                            onClick: () => {
                                handelDelete(user);
                            },
                        },
                        {
                            name: "Modifier",
                            link: "#",
                            type: "normal",
                            onClick: () =>
                                openForm({
                                    ...paramsOpenModal,
                                    objectToModify: optiUser,
                                    title: user.lastname + " " + user.firstname,
                                }),
                        },
                    ]}
                />
            );
        });
        return lstUserCard;
    };

    const showModal = (userInfos, isNew = false) => {
        const {firstname, lastname, email, tel, ...others} = userInfos;
        dispatch(setComponent("Formulaire"));
        dispatch(
            setArgsForComponent({
                objectToModify: [
                    {firstname, lastname, email, tel},
                    {
                        firstname: {label: "Prénom", placeholder: "Prénom"},
                        lastname: {label: "Nom", placeholder: "Nom"},
                        email: {label: "E-Mail", placeholder: "E-Mail"},
                        tel: {label: "Téléphone", placeholder: "Téléphone"},
                        school: {
                            type: "select-one",
                            listOf: {objectType: "schools"},
                        },
                    },
                ],
                saveFunction: {
                    function: isNew ? "submitInsert" : "submitUpdate",
                    args: {objectType: "users"},
                },
            })
        );
        dispatch(
            setTitle(
                !isNew
                    ? `${userInfos.lastname} ${userInfos.firstname} - (${userInfos?.school?.name})`
                    : "Nouvel utilisateur"
            )
        );
        dispatch(display());
    };

    return (
        <>
            <TopGridDisplay>
                <SmallCard
                    texte="Ambassadors"
                    keyFigure={ambassadorList.length}
                    icon={faUser}
                />
            </TopGridDisplay>
            <TopGridDisplay>{generateUsersCards(ambassadorList)}</TopGridDisplay>
        </>
    );
}

export default Ambassadors;
